import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getTicketasignar } from '../../../redux/actions';
import CardContainer from "../Asignartecnico/CardsContainer";
import PacmanLoader from 'react-spinners/PacmanLoader';
import styles from "../Asignartecnico/Card.module.css";

const Asignartecnico = () => {
    const dispatch = useDispatch();
    const asignartec = useSelector((state) => state.ticketasignar);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const loadTickets = async () => {
            await dispatch(getTicketasignar());
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        };

        loadTickets();
    }, [dispatch]);

    return (
        <div>
            <h2>Asignar Técnico</h2>
            {loading ? (
                <div className={styles.spinnerContainer}>
                    <PacmanLoader color="#3559d1" loading={loading} size={40} />
                </div>
            ) : (
                <CardContainer asignartec={asignartec} />
            )}
        </div>
    );
};

export default Asignartecnico;
