import React, { useEffect, useState } from 'react';
import { FaCheckCircle } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getTecnicoti, newRegistro } from '../../../redux/actions';
import { useNavigate } from 'react-router-dom';
import PacmanLoader from 'react-spinners/PacmanLoader';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import styles from "./Card.module.css";

const MySwal = withReactContent(Swal);

const Card = ({ ticketData, equipoCod, sessionUser, email }) => {
    const dispatch = useDispatch();
    const tecnicoti = useSelector((state) => state.tecnicoti);
    const navigate = useNavigate();
    const [selectedTechnician, setSelectedTechnician] = useState('');
    const [observation, setObservation] = useState('');
    const [showAssign, setShowAssign] = useState(false);
    const [loading, setLoading] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');

    useEffect(() => {
        dispatch(getTecnicoti());
    }, [dispatch]);

    const handleTechnicianChange = (e) => {
        const value = e.target.value;
        setSelectedTechnician(value);
        setShowAssign(value !== '');
    };

    const handleAssign = async () => {
        setLoading(true);

        if (selectedTechnician === '-1') {
            const data = {
                fecha: new Date().toISOString(),
                Observacion: observation,
                TicketId: ticketData.TicketId,
                nombreUsuario: 'Pendiente',
                EstadoId: 3,
                correoUsuario: '',
                UsuarioId: null
            };

            try {
                await dispatch(newRegistro(data));
                setAlertMessage('El ticket queda en estado pendiente.');
                setSelectedTechnician('');
                setObservation('');
                setShowAssign(false);
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    text: 'Hubo un error al asignar el ticket.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        } else {
            const selectedTecnico = tecnicoti.find(tecnico => tecnico.id === parseInt(selectedTechnician));

            const data = {
                fecha: new Date().toISOString(),
                Observacion: observation,
                TicketId: ticketData.TicketId,
                nombreUsuario: selectedTecnico.nombre,
                EstadoId: 2,
                correoUsuario: selectedTecnico.email,
                UsuarioId: selectedTecnico.id
            };

            try {
                await dispatch(newRegistro(data));
                setAlertMessage('Se ha asignado o actualizado el técnico.');
                setSelectedTechnician('');
                setObservation('');
                setShowAssign(false);
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    text: 'Hubo un error al asignar el ticket.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        }

        setLoading(false);
    };

    useEffect(() => {
        if (alertMessage) {
            MySwal.fire({
                title: 'Éxito',
                text: alertMessage,
                icon: 'success',
                confirmButtonText: 'Aceptar'
            }).then((result) => {
                if (result.isConfirmed) {
                    navigate('/asigtec'); // Navegar a la ruta después de aceptar el mensaje
                    window.location.reload(); // Forzar actualización de la página
                }
            });

            const timeout = setTimeout(() => {
                setAlertMessage('');
            }, 3000);

            return () => clearTimeout(timeout);
        }
    }, [alertMessage, navigate]);

    const handleResolution = () => {
        navigate(`/resoltick/${ticketData.TicketId}`, {
            state: {
                nombre: ticketData.nombre,
                fecha: ticketData.fecha,
                tecnico: equipoCod,
                UsuarioId: ticketData.UsuarioId,
                email:email
            }
        });
    };

    let cardClassName = styles.card;

    switch (ticketData.EstadoId) {
        case 1:
            cardClassName += ` ${styles.created}`;
            break;
        case 2:
            cardClassName += ` ${styles.assigned}`;
            break;
        case 3:
            cardClassName += ` ${styles.pending}`;
            break;
        default:
            break;
    }

    const formatDate = (dateString) => {
        const options = {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        };
        return new Date(dateString).toLocaleString('es-ES', options).replace(/\//g, '-');
    };

    return (
        <div className={cardClassName}>
            <div className={styles.cardBody}>
                <h5 className={styles.title}>Ticket #: {ticketData.TicketId}</h5>
                <p className={styles.info}>Nombre: {ticketData.nombre}</p>
                <p className={styles.info}>Fecha: {formatDate(ticketData.fecha)}</p>
                <p className={styles.info}>Observación: {ticketData.observacion}</p>        
                {equipoCod && <p className={styles.info}>Técnico: {equipoCod}</p>}
                <p className={styles.info}>Estado: {ticketData.Estado}</p>

                {ticketData.EstadoId === 2 && (
                    <FaCheckCircle 
                        onClick={handleResolution} 
                        className={styles.resolutionIcon}
                    />
                )}

                <select value={selectedTechnician} onChange={handleTechnicianChange} className={styles.select}>
                    <option value="">Selecciona un técnico</option>
                    <option value="-1">Pendiente</option>
                    {tecnicoti.map((tecnico) => (
                        <option key={tecnico.id} value={tecnico.id}>{tecnico.nombre}</option>
                    ))}
                </select>

                {showAssign && (
                    <div className={styles.assignSection}>
                        <input 
                            type="text" 
                            value={observation} 
                            onChange={(e) => setObservation(e.target.value)} 
                            placeholder="Observaciones"
                            className={styles.input}
                        />
                        <button onClick={handleAssign} className={styles.button}>
                            Asignar
                        </button>
                    </div>
                )}

                {loading && (
                    <div className={styles.spinnerContainer}>
                        <PacmanLoader color="#3559d1" loading={loading} size={40} />
                    </div>
                )}
            </div>
        </div>
    );
};

export default Card;
