import React, { useState, useEffect } from "react";
import Card from "../HistorServicios/Card";
import styles from "./CardContainer.module.css";

export default function CardContainer({ allticketced, searchTerm }) {
  const [currentPage, setCurrentPage] = useState(1);
  const cardsPerPage = 12;

  useEffect(() => {
    setCurrentPage(1); // Restablece la página a 1 cuando el término de búsqueda cambia
  }, [searchTerm]);

  if (!Array.isArray(allticketced) || allticketced.length === 0) {
    return <p></p>;
  }

  const allTickets = allticketced.flatMap(item =>
    item.tickets ? item.tickets.map(ticket => ({
      ...ticket,
      nombre: item.usuario.nombre
    })) : []
  );

  const latestTickets = allTickets.reduce((acc, ticket) => {
    const ticketDate = new Date(ticket.fecha);
    if (!acc[ticket.TicketId] || ticketDate > new Date(acc[ticket.TicketId].fecha)) {
      acc[ticket.TicketId] = ticket;
    }
    return acc;
  }, {});

  const latestTicketsArray = Object.values(latestTickets);
  const sortedTickets = latestTicketsArray.sort((a, b) => new Date(b.fecha) - new Date(a.fecha));
  const filteredTickets = sortedTickets.filter(ticket =>
    ticket.TicketId.toString().includes(searchTerm) || 
    ticket.nombre.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const indexOfLastCard = currentPage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCards = filteredTickets.slice(indexOfFirstCard, indexOfLastCard);
  const totalPages = Math.ceil(filteredTickets.length / cardsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className={styles.container}>
      <div className={styles.pagination}>
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          Anterior
        </button>
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? styles.activePage : ""}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
        >
          Siguiente
        </button>
      </div>
      <div className={styles.container1}>
        {currentCards.length > 0 ? (
          currentCards.map((ticket) => (
            <Card key={ticket.TicketId} ticket={ticket} />
          ))
        ) : (
          <p>No se encontraron resultados para "{searchTerm}".</p>
        )}
      </div>
    </div>
  );
}
