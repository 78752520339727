// Detail.jsx
import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { idTicketHistorial } from "../../redux/actions";
import { format } from "date-fns";

function Detail() {
    const { ticket } = useParams();
    // console.log("Ticket ID:", ticket);
    const dispatch = useDispatch();
    const historial = useSelector((state) => state.tickhistorial);
    // console.log("Historial:", historial);

    useEffect(() => {
        if (ticket) {
            dispatch(idTicketHistorial(ticket));
        }
    }, [dispatch, ticket]);

    if (!historial || !historial.registros) {
        return <div>Cargando...</div>;
    }

    return (
        <div>
            <h1>Detalles del Ticket</h1>
            {historial.registros.length === 0 ? (
                <p>No hay registros disponibles para este ticket.</p>
            ) : (
                <>
                    {historial.registros.map((registro) => (
                        <div key={registro.id}>
                            <p><strong># Ticket: </strong> {registro.TicketId}</p>
                            <p><strong>Estado: </strong> {registro.estadoDescripcion}</p>
                            <p><strong>Fecha actualización: </strong> {format(new Date(registro.fecha), 'dd/MM/yyyy HH:mm:ss')}</p>
                            {/* <p><strong>Observación: </strong> {registro.observacion}</p> */}
                        </div>
                    ))}
                </>
            )}
        </div>
    );
}

export default Detail;
