import React, { useState } from "react";
import { format } from "date-fns";
import { FaCheckCircle } from 'react-icons/fa'; // Importa el ícono de resolución
import styles from "./Card.module.css";
import TicketModal from "../../view/TicketDetailModal/TicketDetailModal"; // Asegúrate de importar el componente Modal aquí

function Card({ ticketData, equipoCod }) {
    const { registros } = ticketData;
    const [showModal, setShowModal] = useState(false);
    const [selectedTicketId, setSelectedTicketId] = useState(null); // Estado para almacenar el TicketId seleccionado

    const formatFecha = (fecha) => {
        return format(new Date(fecha), "yyyy-MM-dd HH:mm:ss");
    };

    const handleCloseModal = () => {
        setShowModal(false);
        setSelectedTicketId(null); // Limpiar el TicketId seleccionado cuando se cierra el modal
    };

    const handleShowModal = (ticketId) => {
        setSelectedTicketId(ticketId); // Establecer el TicketId seleccionado
        setShowModal(true);
    };

    const shouldRender =
        registros &&
        registros.length > 0 &&
        registros[0].estadoDescripcion !== "Cerrado" &&
        registros[0].estadoDescripcion !== "Cancelado";

    if (!shouldRender) {
        return null;
    }

    let cardClassName = styles.card;

    switch (registros[0].estadoDescripcion) {
        case "Creado":
            cardClassName += ` ${styles.open}`;
            break;
        case "En Proceso":
            cardClassName += ` ${styles.inProgress}`;
            break;
        case "Pendiente":
            cardClassName += ` ${styles.pending}`;
            break;
        case "Resuelto":
            cardClassName += ` ${styles.resuelto}`;
            break;
        default:
            break;
    }

    return (
        <div className={cardClassName}>
            <div className={styles.cardContent}>
                <div className={styles.title}>
                    <p className={styles.info}>
                        <strong>Ticket #:</strong> {registros[0].TicketId}
                    </p>
                </div>
                <p className={styles.info}>
                    <strong>Fecha Última Actualización:</strong> {formatFecha(registros[0].fecha)}
                </p>
                <p className={styles.info}>
                    <strong>Estado Ticket:</strong> {registros[0].estadoDescripcion}
                </p>
                <p className={`${styles.info} ${styles.observacion}`}>
                    <strong>Observación:</strong> {registros[0].observacion}
                </p>
                <p className={styles.info}>
                    <strong>Código:</strong> {equipoCod}
                </p>
                <button onClick={() => handleShowModal(registros[0].TicketId)}>Ver Detalle</button>
                <TicketModal
                    show={showModal}
                    onHide={handleCloseModal}
                    ticketId={selectedTicketId} // Pasar solo el TicketId seleccionado
                    equipoCod={equipoCod}
                />
            </div>
        </div>
    );
}

export default Card;
