import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./reducer";
import { thunk } from 'redux-thunk'; // Importa 'thunk' en lugar de 'default'

const composeEnhancer = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const store = createStore(
    rootReducer,
    composeEnhancer(applyMiddleware(thunk))
);

export default store;
