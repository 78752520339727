// src/view/Tickets/HistorialServicios.js
import React from 'react';
import Calendar from '../../Components/Calendar/Calendar';

const HistorialServicios = () => {
    return (
        <div>
            <h2>Agendador de espacios</h2>
            <Calendar />
        </div>
    );
};

export default HistorialServicios;
