import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import log from '../Loging/Lisc.png';
import styles from '../Loging/Login.module.css'; // Importar el módulo CSS
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { getUsuarios, validateToken } from '../../redux/actions';
import { useNavigate } from 'react-router-dom';

export default function Login() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [step, setStep] = useState(1);
  const [cedula, setCedula] = useState('');
  const [error, setError] = useState('');
  const { register, handleSubmit, formState: { errors } } = useForm();
  const usuario = useSelector((state) => state.usuario);
  const usuarioError = useSelector((state) => state.usuarioError); // Seleccionar el error de usuario
  const token = useSelector((state) => state.token);

  useEffect(() => {
    if (token.message === 'Token válido') {
      navigate('/home');
    } else if (token.message === 'Token inválido') {
      setError('El token es inválido, por favor verifíquelo.');
    } else if (token.message === 'Token expirado') {
      setError('El token ha expirado, por favor genere otro.');
    }
  }, [token, navigate]);

  const onSubmitCedula = async (data) => {
    setCedula(data.cedula);
    await dispatch(getUsuarios(data.cedula));
    if (usuarioError === 'Documento no existe o esta deshabilitado, Comuniquese con el área TI') {
      setError(usuarioError);
    } else {
      setError('');
      setStep(2);
    }
  };

  const onSubmitToken = (data) => {
    setError('');
    dispatch(validateToken(data.token));
  };

  return (
    <div className={styles.container}>
      <div className={`row ${styles['full-width']}`}>
        <div className={`col-md-6 ${styles['left-column']}`}>
          <img src={log} alt="Incolab Logo" className={`img-fluid ${styles.customlogo}`} />
          <h3>Sistema de gestión de tickets</h3>
          <h3>Incolab Services Colombia S.A.S</h3>
        </div>
        <div className={`col-md-6 ${styles['right-column']}`}>
          <div className={`card ${styles.card}`}>
            <div className={`card-body ${styles['card-body']}`}>
              {step === 1 && (
                <form onSubmit={handleSubmit(onSubmitCedula)}>
                  <div className={`form-group ${styles['form-group']}`}>
                    <label htmlFor="cedula"><h5>Digite su número de Documento</h5></label><br /><br />
                    <input
                      type="text"
                      id="cedula"
                      {...register('cedula', { required: true, minLength: 4, maxLength: 12 })}
                      className={`form-control ${styles['form-control']}`}
                    />
                    {errors.cedula && <span className="text-danger">Este campo es obligatorio y debe tener entre 4 y 12 caracteres</span>}
                    {usuarioError === 'Documento no existe o esta deshabilitado, Comuniquese con el área TI' && <span className="text-danger">{usuarioError}</span>}
                  </div><br />
                  <button type="submit" className={`btn btn-primary btn-block ${styles['btn-primary']}`}>Aceptar</button>
                </form>
              )}
              {step === 2 && (
                <div>
                  <p>Si se encuentra registrado en nuestro sistema, un token llegará a la bandeja de su correo. Por favor, digítelo a continuación:</p>
                  {error && <div className={`alert alert-danger ${styles['alert-danger']}`}>{error}</div>}
                  <form onSubmit={handleSubmit(onSubmitToken)}>
                    <div className={`form-group ${styles['form-group']}`}>
                      <label htmlFor="token"><h5>Digite el token:</h5></label><br /><br />
                      <input
                        type="text"
                        id="token"
                        {...register('token', { required: true })}
                        className={`form-control ${styles['form-control']}`}
                      />
                      {errors.token && <span className="text-danger">Este campo es obligatorio</span>}
                    </div><br />
                    <button type="submit" className={`btn btn-primary btn-block ${styles['btn-primary']}`}>Aceptar</button>
                  </form>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <footer className={styles.footer}>
        Desarrollado por el equipo gestión TI © Incolab Services Colombia 2024
      </footer>
    </div>
  );
}
