import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, Link } from 'react-router-dom';
import { logout } from '../../redux/actions';
import { FaHome, FaFileAlt, FaSearch, FaHistory, FaCalendarAlt, FaUserCog, FaSignOutAlt, FaBars } from 'react-icons/fa';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Sidebar.module.css';

const Sidebar = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const usuario = useSelector(state => state.usuario);
    const roleId = usuario.usuario.rolid; // Adjust according to your user state structure
    const [isOpen, setIsOpen] = useState(false);

    const handleLogout = () => {
        dispatch(logout());
        navigate('/login');
    };

    const toggleSidebar = () => {
        setIsOpen(!isOpen);
    };

    const handleLinkClick = () => {
        setIsOpen(false);
    };

    return (
        <div>
            <button className={styles.menuButton} onClick={toggleSidebar}>
                <FaBars />
            </button>
            <div className={`${styles.sidebarWrapper} ${isOpen ? styles.open : ''}`}>
                <div className={styles.sidebarHeading}>SIGESTIC</div>
                <div className="list-group list-group-flush">
                    <Link to="/home" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                        <FaHome className={styles.sidebarIcon} /> Home
                    </Link>
                    <Link to="/generar-ticket" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                        <FaFileAlt className={styles.sidebarIcon} /> Generar Ticket
                    </Link>
                    <Link to="/seguimiento-ticket" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                        <FaSearch className={styles.sidebarIcon} /> Seguimiento Ticket
                    </Link>
                    <Link to="/histtick" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                        <FaHistory className={styles.sidebarIcon} /> Historial Ticket
                    </Link>
                    <Link to="/agendamiento" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                        <FaCalendarAlt className={styles.sidebarIcon} /> Agendador Sala
                    </Link>
                    {roleId === 1 && (
                        <Link to="/tecnico" className={`list-group-item ${styles.listGroupItem}`} onClick={handleLinkClick}>
                            <FaUserCog className={styles.sidebarIcon} /> Técnico
                        </Link>
                    )}
                    <button onClick={() => { handleLogout(); handleLinkClick(); }} className={`list-group-item ${styles.listGroupItem} ${styles.btnLink}`}>
                        <FaSignOutAlt className={styles.sidebarIcon} /> Cerrar sesión
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Sidebar;
