import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import PacmanLoader from 'react-spinners/PacmanLoader';
import styles from './Home.module.css';
import Grafico from '../Grafico/Grafico';

const Home = () => {
    const usuario = useSelector(state => state.usuario);
    const nombre = usuario.usuario?.nombre || "Invitado";
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }, []);

    return (
        <div>
            {loading ? (
                <div className={styles.spinnerContainer}>
                    <PacmanLoader color="#3559d1" loading={loading} size={40} />
                </div>
            ) : (
                <div className={styles.home}>
                    <h3>Bienvenido {nombre}</h3>
                    <Grafico />
                </div>
            )}
        </div>
    );
};

export default Home;
