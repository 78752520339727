import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2'; // Importa SweetAlert
import { getAllusuarios, getArea, getCargos, getRol, postuser } from '../../../redux/actions';
import {
  Container, TextField, Button, Grid, Paper, Typography, Modal, Box,
  IconButton, MenuItem, Select, FormControl, InputLabel
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';
import { createSelector } from 'reselect';

const selectUsuarios = createSelector(
  (state) => state.allusuarios.usuarios,
  (usuarios) => usuarios || []
);

const selectCargos = createSelector(
  (state) => state.cargo || [],
  (cargos) => cargos.sort((a, b) => a.nombre.localeCompare(b.nombre))
);

const selectAreas = createSelector(
  (state) => state.area || [],
  (areas) => areas.sort((a, b) => a.descripcion.localeCompare(b.descripcion))
);
// const selectAreas = (state) => state.area || [];
const selectRol = (state) => state.rol || [];

const FormularioUsuario = () => {

  const dispatch = useDispatch();
  const allusuarios = useSelector(selectUsuarios);
  const cargos = useSelector(selectCargos);
  const areas = useSelector(selectAreas);
  const roles = useSelector(selectRol);

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [nombre, setNombre] = useState('');
  const [cedula, setCedula] = useState('');
  const [email, setEmail] = useState('');
  const [selectedCargo, setSelectedCargo] = useState('');
  const [selectedArea, setSelectedArea] = useState('');
  const [selectedRol, setSelectedRol] = useState('');

  useEffect(() => {
    dispatch(getAllusuarios());
    dispatch(getCargos());
    dispatch(getArea());
    dispatch(getRol());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setSearchTerm('');
    setOpen(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar que todos los campos estén llenos
    if (!nombre || !cedula || !email || !selectedCargo || !selectedArea || !selectedRol) {
      Swal.fire({
        icon: 'warning',
        title: 'Campos incompletos',
        text: 'Por favor, completa todos los campos antes de guardar.',
      });
      return;
    }

    if (!/^\S+@\S+\.\S+$/.test(email)) {
      Swal.fire({
        icon: 'warning',
        title: 'Email inválido',
        text: 'Por favor, ingresa un email válido.',
      });
      return;
    }


    const newUser = {
      nombre,
      cedula,
      email,
      cargosid: selectedCargo,
      areaid: selectedArea,
      rolid: selectedRol,
    };

    dispatch(postuser(newUser))
      .then((response) => {
        // Verifica si la respuesta fue exitosa
        console.log("RESPUESTA:::", response.status)

        // if (response && response.status === 200) {
        Swal.fire({
          icon: 'success',
          title: 'Usuario creado',
          text: 'Se ha creado el usuario con éxito',
        });

        // Limpiar los campos del formulario después de la creación exitosa
        setNombre('');
        setCedula('');
        setEmail('');
        setSelectedCargo('');
        setSelectedArea('');
        setSelectedRol('');

        // Recargar la lista de usuarios para reflejar el nuevo usuario
        dispatch(getAllusuarios());
        // } else {
        //   throw new Error('Error al crear el usuario');
        // }
      })
    // .catch((error) => {
    //   // Mostrar un error específico si la creación falla
    //   Swal.fire({
    //     icon: 'error',
    //     title: 'Error',
    //     text: `Hubo un problema al crear el usuario: ${error.message}`,
    //   });
    // });
  };



  const filteredUsuarios = allusuarios.filter((usuario) =>
    usuario.nombre.toLowerCase().includes(searchTerm.toLowerCase()) ||
    usuario.cedula.includes(searchTerm)
  ).sort((a, b) => b.id - a.id);


  // console.log("USER::",filteredUsuarios)
  const columns = [
    { field: 'id', headerName: 'ID', width: 50 },
    { field: 'nombre', headerName: 'Nombre', width: 130 },
    { field: 'cedula', headerName: 'Cédula', width: 130 },
    { field: 'email', headerName: 'Email', width: 200 },
    { field: 'cargo', headerName: 'Cargo', width: 150 },
    { field: 'area', headerName: 'Área', width: 130 },
    { field: 'rol', headerName: 'Rol', width: 80 },
  ];

  return (
    <Container maxWidth="md">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
            <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
              Crear Usuarios
            </Typography>
            <form onSubmit={handleSubmit} noValidate>
              <Grid container spacing={1} alignItems="center">
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Nombre"
                    fullWidth
                    size="small"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    InputProps={{
                      style: { height: '36px', fontSize: '0.75rem' },
                    }}
                    InputLabelProps={{
                      style: { fontSize: '0.75rem' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Cédula"
                    fullWidth
                    size="small"
                    value={cedula}
                    onChange={(e) => setCedula(e.target.value)}
                    InputProps={{
                      style: { height: '36px', fontSize: '0.75rem' },
                    }}
                    InputLabelProps={{
                      style: { fontSize: '0.75rem' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <TextField
                    label="Email"
                    fullWidth
                    size="small"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    InputProps={{
                      style: { height: '36px', fontSize: '0.75rem' },
                    }}
                    InputLabelProps={{
                      style: { fontSize: '0.75rem' },
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="cargo-label">Cargo</InputLabel>
                    <Select
                      labelId="cargo-label"
                      value={selectedCargo}
                      onChange={(e) => setSelectedCargo(e.target.value)}
                      label="Cargo"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            fontSize: '0.75rem', // Ajusta el tamaño de la fuente en las opciones
                          },
                        },
                      }}
                      sx={{ fontSize: '0.75rem' }} // Ajusta el tamaño de la fuente en el select
                    >
                      {cargos.map((cargo) => (
                        <MenuItem key={cargo.id} value={cargo.id}>
                          {cargo.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="area-label">Área</InputLabel>
                    <Select
                      labelId="area-label"
                      value={selectedArea}
                      onChange={(e) => setSelectedArea(e.target.value)}
                      label="Área"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            fontSize: '0.75rem', // Ajusta el tamaño de la fuente en las opciones
                          },
                        },
                      }}
                      sx={{ fontSize: '0.75rem' }} // Ajusta el tamaño de la fuente en el select
                    >
                      {areas.map((area) => (
                        <MenuItem key={area.id} value={area.id}>
                          {area.descripcion}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6}>
                  <FormControl fullWidth size="small">
                    <InputLabel id="rol-label">Rol</InputLabel>
                    <Select
                      labelId="rol-label"
                      value={selectedRol}
                      onChange={(e) => setSelectedRol(e.target.value)}
                      label="Rol"
                      MenuProps={{
                        PaperProps: {
                          style: {
                            fontSize: '0.75rem', // Ajusta el tamaño de la fuente en las opciones
                          },
                        },
                      }}
                      sx={{ fontSize: '0.75rem' }} // Ajusta el tamaño de la fuente en el select
                    >
                      {roles.map((rol) => (
                        <MenuItem key={rol.id} value={rol.id}>
                          {rol.nombre}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12}>
                  <Button type="submit" variant="contained" color="primary" size="small" style={{ fontSize: '0.75rem' }}>
                    Guardar
                  </Button>
                  <Button variant="outlined" color="secondary" size="small" style={{ fontSize: '0.75rem', marginLeft: '10px' }} onClick={handleOpen}>
                    Ver Usuarios
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Paper>
        </Grid>

        <Modal open={open} onClose={handleClose}>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              width: '80%',
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              maxHeight: '90vh',
              overflow: 'hidden',
            }}
          >
            <IconButton
              aria-label="close"
              onClick={handleClose}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
                color: (theme) => theme.palette.grey[500],
              }}
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" component="h2" gutterBottom>
              Lista de Usuarios
            </Typography>
            <Box sx={{ position: 'sticky', top: 0, backgroundColor: 'background.paper', zIndex: 1, py: 1 }}>
              <TextField
                label="Buscar por nombre o cédula"
                variant="outlined"
                fullWidth
                size="small"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                sx={{ mb: 2 }}
                InputProps={{
                  style: { height: '36px', fontSize: '0.75rem' },
                }}
                InputLabelProps={{
                  style: { fontSize: '0.75rem' },
                }}
              />
            </Box>
            <div style={{ height: 400, width: '100%' }}>
              <DataGrid
                rows={filteredUsuarios}
                columns={columns}
                pageSize={5}
                rowsPerPageOptions={[5]}
                checkboxSelection
                disableSelectionOnClick
              />
            </div>
          </Box>
        </Modal>
      </Grid>
    </Container>
  );
};

export default FormularioUsuario;
