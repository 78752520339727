import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch } from 'react-redux';
import { postResolucion } from '../../../redux/actions';
import styles from './Resolverticket.module.css';
import { format } from 'date-fns';
import PacmanLoader from 'react-spinners/PacmanLoader'; // Importa el spinner
import Swal from 'sweetalert2'; // Importa SweetAlert

const Resolverticket = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { nombre, fecha, tecnico, UsuarioId, email } = location.state;

    // console.log("ESTO::",location.state)

    // console.log(location.state)

    const [formData, setFormData] = useState({
        observaciones: '',
        actividad: [],
        piezas: [],
        software_so: '',
        software_office: '',
        software_adobe_reader: '',
        software_firefox: '',
        software_otro: '',
        software_chrome: '',
        software_acrobat: '',
        software_codec: '',
        software_zip: '',
        software_otro2: ''
    });

    const [loading, setLoading] = useState(false); // Estado para el spinner

    const handleBack = () => {
        navigate('/asigtec');
    };

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked ? [...prevState[name], value] : prevState[name].filter(v => v !== value)
            }));
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!formData.observaciones.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Las observaciones son obligatorias.'
            });
            return;
        }
    
        const resolucionData = {
            observacion: formData.observaciones,
            TicketId: location.pathname.split('/').pop(),
            EstadoId: 4,
            UsuarioId,
            email,
            nombre,
            tecnico,
            limpinter: formData.actividad.includes('Limpieza interna') ? 1 : 0,
            revilimvirus: formData.actividad.includes('Revision y limpieza de virus') ? 1 : 0,
            partformat: formData.actividad.includes('Particionamiento y formateo de disco') ? 1 : 0,
            instacontrola: formData.actividad.includes('Instalacion de controladores') ? 1 : 0,
            instasoftutili: formData.actividad.includes('Instalacion actualizaciones - software utilitario') ? 1 : 0,
            confcorreo: formData.actividad.includes('Configuracion correo electronico') ? 1 : 0,
            revisisarchi: formData.actividad.includes('Revision del sistema de archivo') ? 1 : 0,
            limptemp: formData.actividad.includes('Limpieza de temporales') ? 1 : 0,
            instalso: formData.actividad.includes('Instalacion de SO') ? 1 : 0,
            instalactso: formData.actividad.includes('Instalacion actualizaciones So') ? 1 : 0,
            confred: formData.actividad.includes('Configuracion de red') ? 1 : 0,
            confimpres: formData.actividad.includes('Configuracion impresora, local o red') ? 1 : 0,
            discosata: formData.piezas.includes('Disco Duro - SATA') ? 1 : 0,
            disssd: formData.piezas.includes('Disco Duro - SSD') ? 1 : 0,
            memoriaram: formData.piezas.includes('Memoria RAM') ? 1 : 0,
            bateria: formData.piezas.includes('Batería') ? 1 : 0,
            cargador: formData.piezas.includes('Cargador') ? 1 : 0,
            tarjemadre: formData.piezas.includes('Tarjeta Madre') ? 1 : 0,
            procesador: formData.piezas.includes('Procesador') ? 1 : 0,
            tarjevideo: formData.piezas.includes('Tarjeta de Video') ? 1 : 0,
            tarjeredlan: formData.piezas.includes('Tarjeta de red LAN') ? 1 : 0,
            tarjeredwlan: formData.piezas.includes('Tarjeta de red WLAN') ? 1 : 0,
            pantalla: formData.piezas.includes('Pantalla') ? 1 : 0,
            fuentepod: formData.piezas.includes('Fuente de Poder') ? 1 : 0,
            bateinterna: formData.piezas.includes('Batería interna 3v') ? 1 : 0,
            teclado: formData.piezas.includes('Teclado') ? 1 : 0,
            mouse: formData.piezas.includes('Mouse') ? 1 : 0,
            combinalamb: formData.piezas.includes('Combo inalámbrico') ? 1 : 0,
            diadema: formData.piezas.includes('Diadema') ? 1 : 0,
            adaptarj45: formData.piezas.includes('Adaptador RJ45 - USB') ? 1 : 0,
            multiusb: formData.piezas.includes('Multipuerto USB') ? 1 : 0,
            fundaporta: formData.piezas.includes('Funda Para Portátil') ? 1 : 0,
            padmouse: formData.piezas.includes('Pad Mouse') ? 1 : 0,
            otro: formData.piezas.includes('Otro') ? 1 : 0,
            sisope: formData.software_so,
            suiteoffi: formData.software_office,
            adoberead: formData.software_adobe_reader,
            mozilla: formData.software_firefox,
            otroinst: formData.software_otro,
            goochrom: formData.software_chrome,
            adobeacro: formData.software_acrobat,
            codecklite: formData.software_codec,
            zip: formData.software_zip,
            otroinst2: formData.software_otro2
        };
    
        setLoading(true); // Muestra el spinner
    
        dispatch(postResolucion(resolucionData))
            .then(() => {
                setLoading(false); // Oculta el spinner
                Swal.fire({
                    icon: 'success',
                    title: 'Éxito',
                    text: 'Resolución de Ticket Exitosa',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        setFormData({
                            observaciones: '',
                            actividad: [],
                            piezas: [],
                            software_so: '',
                            software_office: '',
                            software_adobe_reader: '',
                            software_firefox: '',
                            software_otro: '',
                            software_chrome: '',
                            software_acrobat: '',
                            software_codec: '',
                            software_zip: '',
                            software_otro2: ''
                        });
                        navigate('/asigtec'); // Navega solo después de que el usuario confirme el mensaje
                    }
                });
            })
            .catch((error) => {
                setLoading(false); // Oculta el spinner en caso de error
                Swal.fire({
                    icon: 'error',
                    title: 'Error',
                    text: 'Hubo un problema al enviar la resolución.',
                    confirmButtonText: 'Aceptar'
                });
            });
    };
    
    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return format(date, 'yyyy-MM-dd HH:mm:ss');
    };

    return (
        <div className={styles.container}>
            <FaArrowLeft className={styles.backIcon} onClick={handleBack} />
            <h2>Resolución de Ticket</h2>
            <form onSubmit={handleSubmit}>
                <fieldset className={styles.fieldset}>
                    <legend>Información del Ticket # <stron>{location.pathname.split('/').pop()}</stron></legend>
                    <div className={styles.infoRow}>
                        <span><strong>Usuario:</strong> {nombre}</span>
                        <span><strong>Fecha:</strong> {formatDateTime(fecha)}</span>
                        <span><strong>Técnico:</strong> {tecnico}</span>
                    </div>
                </fieldset>

                <fieldset className={styles.fieldset}>
                    <legend>Actividades Realizadas</legend>
                    <div className={styles.checkboxGroup}>
                        {['Limpieza interna', 'Revision y limpieza de virus', 'Particionamiento y formateo de disco',
                            'Instalacion de controladores', 'Instalacion actualizaciones - software utilitario',
                            'Configuracion correo electronico', 'Revision del sistema de archivo',
                            'Limpieza de temporales', 'Instalacion de SO', 'Instalacion actualizaciones So',
                            'Configuracion de red', 'Configuracion impresora, local o red'].map(activity => (
                                <label key={activity}>
                                    <input
                                        type="checkbox"
                                        name="actividad"
                                        value={activity}
                                        checked={formData.actividad.includes(activity)}
                                        onChange={handleChange}
                                    />
                                    {activity}
                                </label>
                            ))}
                    </div>
                </fieldset>

                <fieldset className={styles.fieldset}>
                    <legend>Piezas Reemplazadas</legend>
                    <div className={styles.checkboxGroup}>
                        {['Disco Duro - SATA', 'Disco Duro - SSD', 'Memoria RAM', 'Batería', 'Cargador',
                            'Tarjeta Madre', 'Procesador', 'Tarjeta de Video', 'Tarjeta de red LAN',
                            'Tarjeta de red WLAN', 'Pantalla', 'Fuente de Poder', 'Batería interna 3v',
                            'Teclado', 'Mouse', 'Combo inalámbrico', 'Diadema', 'Adaptador RJ45 - USB',
                            'Multipuerto USB', 'Funda Para Portátil', 'Pad Mouse', 'Otro'].map(piece => (
                                <label key={piece}>
                                    <input
                                        type="checkbox"
                                        name="piezas"
                                        value={piece}
                                        checked={formData.piezas.includes(piece)}
                                        onChange={handleChange}
                                    />
                                    {piece}
                                </label>
                            ))}
                    </div>
                </fieldset>

                <fieldset className={styles.fieldset}>
                    <legend>Software Instalado</legend>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label>Sistema Operativo:</label>
                            <input
                                className={styles.input}
                                name="software_so"
                                value={formData.software_so}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Suite de Office:</label>
                            <input
                                className={styles.input}
                                name="software_office"
                                value={formData.software_office}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label>Adobe Reader:</label>
                            <input
                                className={styles.input}
                                name="software_adobe_reader"
                                value={formData.software_adobe_reader}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Firefox:</label>
                            <input
                                className={styles.input}
                                name="software_firefox"
                                value={formData.software_firefox}
                                onChange={handleChange}
                            />
                        </div>
                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label>7zip:</label>
                            <input
                                className={styles.input}
                                name="software_zip"
                                value={formData.software_zip}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Chrome:</label>
                            <input
                                className={styles.input}
                                name="software_chrome"
                                value={formData.software_chrome}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label>Adobe Acrobat:</label>
                            <input
                                className={styles.input}
                                name="software_acrobat"
                                value={formData.software_acrobat}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Klite:</label>
                            <input
                                className={styles.input}
                                name="software_codec"
                                value={formData.software_codec}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label>Otro:</label>
                            <input
                                className={styles.input}
                                name="software_otro"
                                value={formData.software_otro}
                                onChange={handleChange}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Otro2:</label>
                            <input
                                className={styles.input}
                                name="software_otro2"
                                value={formData.software_otro2}
                                onChange={handleChange}
                            />
                        </div>

                    </div>
                </fieldset>


                <fieldset className={styles.fieldset}>
                    <legend>Observaciones</legend>
                    <textarea
                        className={styles.textarea}
                        name="observaciones"
                        value={formData.observaciones}
                        onChange={handleChange}
                    />
                </fieldset>

                <button type="submit" className={styles.button}>Enviar</button>
            </form>
        </div>
    );
};

export default Resolverticket;
