import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTipofalla, CedAsigequipo, crearTicket } from '../../redux/actions';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import PacmanLoader from 'react-spinners/PacmanLoader';
import './GenerarTicket.css';

const MySwal = withReactContent(Swal);

const GenerarTicket = () => {
    const dispatch = useDispatch();
    const tipofalla = useSelector((state) => state.tipofalla);
    const usuario = useSelector((state) => state.usuario);
    const asigusuario = useSelector((state) => state.asigusuario);

    const [codigo, setCodigo] = useState('');
    const [tipoFallaSeleccionado, setTipoFallaSeleccionado] = useState('');
    const [observaciones, setObservaciones] = useState('');
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        dispatch(getTipofalla());
    }, [dispatch]);

    useEffect(() => {
        if (usuario.usuario && usuario.usuario.cedula) {
            dispatch(CedAsigequipo(usuario.usuario.cedula))
                .catch((err) => {
                    if (err.response && err.response.status === 404) {
                        setError('Usuario no tiene asignaciones de equipo');
                    }
                });
        }
    }, [dispatch, usuario]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError(''); // Clear any previous error messages
        const asignacionSeleccionada = asigusuario.find((a) => {
            return String(a.equipo.cod) === String(codigo);
        });

        const AsignacionId = asignacionSeleccionada ? asignacionSeleccionada.asignacion.id : null;

        if (!AsignacionId) {
            setError('No se pudo encontrar la asignación para el código seleccionado');
            return;
        }

        const nuevoTicket = {
            fecha: new Date().toISOString(),
            Observacion: observaciones,
            AsignacionId: AsignacionId,
            FallaId: tipoFallaSeleccionado,
            EstadoId: 1,
            nombreUsuario: usuario.usuario.nombre,
            correoUsuario: usuario.usuario.email,
            cod: asignacionSeleccionada.equipo.cod,
        };

        setLoading(true);

        try {
            const resultAction = await dispatch(crearTicket(nuevoTicket));
            if (crearTicket.fulfilled.match(resultAction)) {
                const ticketId = resultAction.payload.Ticket.id;
                setTimeout(() => {
                    setLoading(false);
                    MySwal.fire({
                        title: 'Ticket Generado',
                        text: `Se ha generado su ticket con el número ${ticketId}`,
                        icon: 'success',
                        confirmButtonText: 'Aceptar'
                    });
                }, 2000); // Espera 4 segundos antes de mostrar el SweetAlert
            } else {
                setLoading(false);
                // console.error('Error al crear el ticket:', resultAction.error);
            }
        } catch (error) {
            setLoading(false);
            // console.error('Error al crear el ticket:', error);
        }

        setCodigo('');
        setTipoFallaSeleccionado('');
        setObservaciones('');
    };

    return (
        <div>
            <h2>Generar Ticket</h2><br />
            {error && <div className="alert alert-danger">{error}</div>}
            <form onSubmit={handleSubmit}>
                <div className="form-group">
                    <label htmlFor="codigo">Código:</label>
                    <select
                        id="codigo"
                        className="form-control"
                        value={codigo}
                        onChange={(e) => setCodigo(e.target.value)}
                        required
                    >
                        <option value="">Seleccionar código</option>
                        {asigusuario.length === 0 ? (
                            <option value="" disabled>No tiene equipo asignado</option>
                        ) : (
                            asigusuario.map((asignacion) => (
                                <option key={asignacion.equipo.cod} value={asignacion.equipo.cod}>
                                    {asignacion.equipo.cod}
                                </option>
                            ))
                        )}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="tipoFalla">Tipo de Falla:</label>
                    <select
                        id="tipoFalla"
                        className="form-control"
                        value={tipoFallaSeleccionado}
                        onChange={(e) => setTipoFallaSeleccionado(e.target.value)}
                        required
                    >
                        <option value="">Seleccionar tipo de falla</option>
                        {tipofalla.map((falla) => (
                            <option key={falla.id} value={falla.id}>
                                {falla.descripcion}
                            </option>
                        ))}
                    </select>
                </div>

                <div className="form-group">
                    <label htmlFor="observaciones">Observaciones:</label>
                    <textarea
                        id="observaciones"
                        className="form-control"
                        value={observaciones}
                        onChange={(e) => setObservaciones(e.target.value)}
                        rows={4}
                        placeholder="Escribe aquí tus observaciones"
                        required
                    />
                </div><br />

                <button type="submit" className="btn btn-primary">
                    Generar Ticket
                </button>
            </form>
            {loading && (
                <div className="spinner-container">
                    <PacmanLoader color="#3559d1" loading={true} margin={12} size={40} />
                </div>
            )}
        </div>
    );
};

export default GenerarTicket;
