import React from "react";
import { format } from "date-fns";
import { FaCheckCircle, FaClock, FaHourglassHalf, FaTimesCircle, FaBan, FaSpinner } from "react-icons/fa";
import styles from "./Card.module.css"; // Importa los estilos

function Card({ ticket }) {
  if (!ticket) {
    return null;
  }

  const formatFecha = (fecha) => {
    return format(new Date(fecha), "yyyy-MM-dd HH:mm:ss");
  };

  const getEstadoDescripcion = (estadoId) => {
    switch (estadoId) {
      case 1:
        return { descripcion: "Creado", icon: <FaCheckCircle className={styles.iconCreated} /> };
      case 2:
        return { descripcion: "En proceso", icon: <FaSpinner className={styles.iconInProcess} /> };
      case 3:
        return { descripcion: "Pendiente", icon: <FaHourglassHalf className={styles.iconPending} /> };
      case 4:
        return { descripcion: "Resuelto", icon: <FaTimesCircle className={styles.iconClosed}  /> };
      case 5:
        return { descripcion: "Cerrado", icon:  <FaCheckCircle className={styles.iconResolved} /> };
      case 6:
        return { descripcion: "Cancelado", icon: <FaBan className={styles.iconCancelled} /> };
      default:
        return { descripcion: "Desconocido", icon: null };
    }
  };

  const estado = getEstadoDescripcion(ticket.EstadoId);

  return (
    <div className={styles.card}>
      <div className={styles.cardBody}>
        <div className={styles.cardHeader}>
          <p>
            <strong>Ticket:</strong> {ticket.TicketId || "N/A"}
          </p>
          <div className={styles.icon}>{estado.icon}</div>
        </div>
        <p className={styles.cardInfo}>
          <strong>Fecha Última Actualización:</strong> {formatFecha(ticket.fecha) || "N/A"}
        </p>
        <p className={`${styles.cardInfo} ${styles.cardObservation}`}>
          <strong>Observación:</strong> {ticket.observacion || "N/A"}
        </p>
        <p className={styles.cardInfo}>
          <strong>Usuario:</strong> {ticket.nombre || "N/A"}
        </p>
        <p className={styles.cardInfo}>
          <strong>Estado:</strong> {estado.descripcion || "N/A"}
        </p>
        <p className={styles.cardInfo}>
          <strong>Técnico:</strong> {ticket.UsuarioId || "Sin Asignar"}
        </p>
        <p className={styles.cardInfo}>
          <strong>Votación:</strong> {ticket.votacion || ""}
        </p>
      </div>
    </div>
  );
}

export default Card;
