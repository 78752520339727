import React, { useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Swal from 'sweetalert2';
import { getEquipo, postequi, getSO, getMarca, getTipoequipo, getCritici } from '../../../redux/actions';
import {
    Container, TextField, Button, Grid, Paper, Typography, MenuItem, Select, FormControl, InputLabel, Modal, Box, IconButton
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import CloseIcon from '@mui/icons-material/Close';

const FormularioEquipo = () => {
    const dispatch = useDispatch();

    const tipoequipo = useSelector((state) => state.tipoequipo || []);
    const sistemasO = useSelector((state) => state.sistemasOperativos || []);
    const equipos = useSelector((state) => state.equipos || []);
    const marca = useSelector((state) => state.marca || []);
    const criticidad = useSelector((state) => state.criticidad || []);
    // console.log("SEVA::",equipos)
    const [open, setOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [formValues, setFormValues] = useState({
        cod: '',
        modelo: '',
        serial: '',
        fechacompra: '',
        procesador: '',
        memoria: '',
        capacidadHD: '',
        tiporam: '',
        valor: '',
        tipoequipoId: '',
        SistemaOId: '',
        MarcaId: '',
        CriticId: ''
    });

    // Definir las opciones para tipo de RAM
    const tipoRAMOptions = ['DDR2', 'DDR3', 'DDR4', 'DDR5'];

    // Usar useEffect para cargar datos una sola vez
    useEffect(() => {
        dispatch(getSO());
        dispatch(getEquipo());
        dispatch(getMarca());
        dispatch(getTipoequipo());
        dispatch(getCritici());
    }, [dispatch]);

    // Memorizar sistemas operativos para evitar re-renderizados innecesarios
    const memoizedSistemasO = useMemo(() => sistemasO, [sistemasO]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setSearchTerm('');
        setOpen(false);
    };

    const formatCurrency = (value) => {
        if (!value) return '';
        const formattedValue = value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        return `$ ${formattedValue}`;
    };

    const parseCurrency = (value) => {
        return value.replace(/\./g, '').replace('$', '').trim();
    };

    const handleChange = (e) => {
        const { name, value } = e.target;

        if (name === 'valor') {
            setFormValues({
                ...formValues,
                [name]: formatCurrency(value)
            });
        } else {
            setFormValues({
                ...formValues,
                [name]: value
            });
        }
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        const requiredFields = ['cod', 'modelo', 'serial', 'fechacompra', 'procesador', 'memoria', 'capacidadHD', 'tiporam', 'valor', 'tipoequipoId', 'SistemaOId', 'MarcaId', 'CriticId'];
        for (const field of requiredFields) {
            if (!formValues[field]) {
                Swal.fire({
                    icon: 'warning',
                    title: 'Campos incompletos',
                    text: 'Por favor, completa todos los campos antes de guardar.',
                });
                return;
            }
        }

        const equipoConEstado = {
            ...formValues,
            valor: parseCurrency(formValues.valor),  // Limpiar formato de moneda
            estado: 'Bueno'
        };

        try {
            dispatch(postequi(equipoConEstado))
                .then(() => {
                    Swal.fire({
                        icon: 'success',
                        title: 'Equipo creado',
                        text: 'Se ha creado el equipo con éxito',
                    });

                    // Restablecer los valores del formulario
                    setFormValues({
                        cod: '',
                        modelo: '',
                        serial: '',
                        fechacompra: '',
                        procesador: '',
                        memoria: '',
                        capacidadHD: '',
                        tiporam: '',
                        valor: '',
                        tipoequipoId: '',
                        SistemaOId: '',
                        MarcaId: '',
                        CriticId: ''
                    });

                    // Obtener la lista de equipos actualizada
                    dispatch(getEquipo());
                })
                .catch((error) => {
                    // Si hay un error, capturamos el mensaje de error del response y lo mostramos en el Swal
                    const errorMessage = error.response?.data?.message || 'Ocurrió un error al guardar el equipo. Intenta nuevamente.';
                    Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: errorMessage,
                    });
                    console.error('Error al guardar el equipo:', error);
                });
        } catch (error) {
            // Manejar errores inesperados que puedan ocurrir fuera de la promesa
            Swal.fire({
                icon: 'error',
                title: 'Error inesperado',
                text: 'Ocurrió un error inesperado. Intenta nuevamente.',
            });
            console.error('Error inesperado al guardar el equipo:', error);
        }


    };

    const filteredEquipos = equipos
        .filter((equipoObj) => {
            // Verificar que 'cod', 'modelo', y 'serial' existan antes de aplicar toLowerCase()
            const cod = equipoObj.equipo.cod ? equipoObj.equipo.cod.toString().toLowerCase() : '';
            const modelo = equipoObj.equipo.modelo ? equipoObj.equipo.modelo.toLowerCase() : '';
            const serial = equipoObj.equipo.serial ? equipoObj.equipo.serial.toLowerCase() : '';

            const search = searchTerm.toLowerCase(); // Convertir el término de búsqueda a minúsculas

            // Verificar si alguna de las propiedades incluye el término de búsqueda
            return (
                cod.includes(search) ||
                modelo.includes(search) ||
                serial.includes(search)
            );
        })
        .map((equipoObj) => ({
            id: equipoObj.equipo.id, // Acceder a la propiedad 'id' dentro del subobjeto 'equipo'
            cod: equipoObj.equipo.cod,
            modelo: equipoObj.equipo.modelo,
            serial: equipoObj.equipo.serial,
            fechacompra: new Date(equipoObj.equipo.fechacompra).toLocaleDateString('es-ES', {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            }),
            procesador: equipoObj.equipo.procesador,
            memoria: equipoObj.equipo.memoria,
            capacidadHD: equipoObj.equipo.capacidadHD,
            tiporam: equipoObj.equipo.tiporam,
            valor: new Intl.NumberFormat('es-CO', {
                style: 'currency',
                currency: 'COP',
                minimumFractionDigits: 0
            }).format(equipoObj.equipo.valor),
            estado: equipoObj.equipo.estado,
            tipoequipoId: equipoObj.tipoequipo.descripcionte, // Muestra la descripción del tipo de equipo
            SistemaOId: equipoObj.sistemao.descripcionso, // Muestra la descripción del sistema operativo
            MarcaId: equipoObj.marca.marca,
            CriticId: equipoObj.criticidad.descripcion
        }))
        .sort((a, b) => b.id - a.id); // Ordenar por 'id'


    console.log("MODAL::", filteredEquipos);



    const columns = [
        { field: 'id', headerName: 'id', width: 50 },
        { field: 'cod', headerName: 'Código', width: 130 },
        { field: 'modelo', headerName: 'Modelo', width: 130 },
        { field: 'serial', headerName: 'Número de Serie', width: 180 },
        { field: 'fechacompra', headerName: 'Fecha de Compra', width: 150 },
        { field: 'procesador', headerName: 'Procesador', width: 150 },
        { field: 'memoria', headerName: 'Memoria', width: 100 },
        { field: 'capacidadHD', headerName: 'Capacidad HD', width: 150 },
        { field: 'tiporam', headerName: 'Tipo de RAM', width: 130 },
        { field: 'valor', headerName: 'Valor', width: 100 },
        { field: 'estado', headerName: 'Estado', width: 100 },
        { field: 'tipoequipoId', headerName: 'Tipo de Equipo', width: 150 },
        { field: 'SistemaOId', headerName: 'Sistema Operativo', width: 150 },
        { field: 'MarcaId', headerName: 'Marca', width: 150 },
        { field: 'CriticId', headerName: 'Criticidad', width: 150 },
    ];

    return (
        <Container maxWidth="md">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Paper elevation={3} style={{ padding: '20px', marginBottom: '20px' }}>
                        <Typography variant="h5" gutterBottom style={{ fontSize: '1rem' }}>
                            Crear Equipo
                        </Typography>
                        <form onSubmit={handleSubmit} noValidate>
                            <Grid container spacing={1} alignItems="center">
                                {Object.entries(formValues).map(([key, value]) => {
                                    // Excluir ciertos campos del mapeo general
                                    if (['tipoequipoId', 'SistemaOId', 'MarcaId', 'CriticId', 'fechacompra'].includes(key)) {
                                        return null;
                                    }

                                    // Condición para renderizar Select en lugar de TextField para 'tiporam'
                                    if (key === 'tiporam') {
                                        return (
                                            <Grid item xs={12} sm={6} key={key}>
                                                <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                                    <InputLabel id="tiporam-label">Tipo de RAM</InputLabel>
                                                    <Select
                                                        labelId="tiporam-label"
                                                        name="tiporam"
                                                        value={formValues.tiporam}
                                                        onChange={handleChange}
                                                        label="Tipo de RAM"
                                                        sx={{ fontSize: '0.75rem' }}
                                                    >
                                                        {tipoRAMOptions.map((ram) => (
                                                            <MenuItem key={ram} value={ram}>
                                                                {ram}
                                                            </MenuItem>
                                                        ))}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        );
                                    }

                                    // Renderizar TextField para otros campos
                                    return (
                                        <Grid item xs={12} sm={6} key={key}>
                                            <TextField
                                                name={key}
                                                label={key.replace(/([A-Z])/g, ' $1').replace(/^./, str => str.toUpperCase())}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                value={value}
                                                onChange={handleChange}
                                                sx={{ mb: 2 }}
                                                InputProps={{
                                                    style: { fontSize: '0.75rem' },
                                                }}
                                                InputLabelProps={{
                                                    style: { fontSize: '0.75rem' },
                                                }}
                                            />
                                        </Grid>
                                    );
                                })}

                                {/* Campo de Fecha de Compra */}
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name="fechacompra"
                                        label="Fecha de Compra"
                                        type="date"
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        value={formValues.fechacompra}
                                        onChange={handleChange}
                                        sx={{ mb: 2 }}
                                        InputLabelProps={{
                                            shrink: true,
                                            style: { fontSize: '0.75rem' },
                                        }}
                                        InputProps={{
                                            style: { fontSize: '0.75rem' },
                                        }}
                                    />
                                </Grid>

                                {/* Campos Select para Marca, Tipo de Equipo, Sistema Operativo y Criticidad */}
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                        <InputLabel id="marca-label">Marca</InputLabel>
                                        <Select
                                            labelId="marca-label"
                                            name="MarcaId"
                                            value={formValues.MarcaId}
                                            onChange={handleChange}
                                            label="Marca"
                                            sx={{ fontSize: '0.75rem' }}
                                        >
                                            {marca.map((tipo) => (
                                                <MenuItem key={tipo.id} value={tipo.id}>
                                                    {tipo.marca}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                        <InputLabel id="tipoequipo-label">Tipo de Equipo</InputLabel>
                                        <Select
                                            labelId="tipoequipo-label"
                                            name="tipoequipoId"
                                            value={formValues.tipoequipoId}
                                            onChange={handleChange}
                                            label="Tipo de Equipo"
                                            sx={{ fontSize: '0.75rem' }}
                                        >
                                            {tipoequipo.map((tipo) => (
                                                <MenuItem key={tipo.id} value={tipo.id}>
                                                    {tipo.descripcionte}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                        <InputLabel id="sistemao-label">Sistema Operativo</InputLabel>
                                        <Select
                                            labelId="sistemao-label"
                                            name="SistemaOId"
                                            value={formValues.SistemaOId}
                                            onChange={handleChange}
                                            label="Sistema Operativo"
                                            sx={{ fontSize: '0.75rem' }}
                                        >
                                            {memoizedSistemasO.map((so) => (
                                                <MenuItem key={so.id} value={so.id}>
                                                    {so.descripcionso}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <FormControl fullWidth size="small" sx={{ mb: 2 }}>
                                        <InputLabel id="criticidad-label">Criticidad</InputLabel>
                                        <Select
                                            labelId="criticidad-label"
                                            name="CriticId"
                                            value={formValues.CriticId}
                                            onChange={handleChange}
                                            label="Criticidad"
                                            sx={{ fontSize: '0.75rem' }}
                                        >
                                            {criticidad.map((tipo) => (
                                                <MenuItem key={tipo.id} value={tipo.id}>
                                                    {tipo.descripcion}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>

                                {/* Botones de Guardar y Ver Equipos */}
                                <Grid item xs={12}>
                                    <Button type="submit" variant="contained" color="primary" size="small" style={{ fontSize: '0.75rem' }}>
                                        Guardar
                                    </Button>
                                    <Button variant="outlined" color="secondary" size="small" style={{ fontSize: '0.75rem', marginLeft: '10px' }} onClick={handleOpen}>
                                        Ver Equipos
                                    </Button>
                                </Grid>
                            </Grid>
                        </form>
                    </Paper>
                </Grid>

                {/* Modal para Ver Equipos Registrados */}
                <Modal open={open} onClose={handleClose}>
                    <Box
                        sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            width: '80%',
                            bgcolor: 'background.paper',
                            border: '2px solid #000',
                            boxShadow: 24,
                            p: 4,
                            maxHeight: '90vh',
                            overflow: 'auto', // Cambia a 'auto' para permitir el desplazamiento
                        }}
                    >

                        <IconButton
                            aria-label="close"
                            onClick={handleClose}
                            sx={{
                                position: 'absolute',
                                right: 8,
                                top: 8,
                                color: (theme) => theme.palette.grey[500],
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" gutterBottom>
                            Equipos Registrados
                        </Typography>
                        <TextField
                            variant="outlined"
                            fullWidth
                            size="small"
                            label="Buscar por Modelo o Tipo"
                            value={searchTerm}
                            onChange={(e) => setSearchTerm(e.target.value)}
                            style={{ marginBottom: '16px', fontSize: '0.75rem' }}
                        />
                        <div style={{ flexGrow: 1, width: '100%', display: 'flex', flexDirection: 'column' }}>
                            <DataGrid
                                rows={filteredEquipos}
                                columns={columns}
                                getRowId={(row) => row.id} // Ahora 'id' es directamente accesible
                                pageSize={5}
                                rowsPerPageOptions={[5]}
                                autoHeight // Deja que el DataGrid ajuste automáticamente su altura
                            />
                        </div>

                    </Box>
                </Modal>
            </Grid>
        </Container>
    );
};

export default FormularioEquipo;
