import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllTicketUser } from '../../../redux/actions';
import CardContainer from "../HistorServicios/CardContainer";

const HistorialTicket = () => {
    const dispatch = useDispatch();
    const allticketced = useSelector((state) => state.alltickuser);
    const [searchTerm, setSearchTerm] = useState("");

    useEffect(() => {
        dispatch(getAllTicketUser());
    }, [dispatch]);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    return (
        <div style={{ padding: '0px', maxWidth: '1200px', margin: '0 auto' }}>
            <h2>Historial De Servicios</h2>
            <input
                type="text"
                placeholder="Buscar.."
                value={searchTerm}
                onChange={handleSearchChange}
                style={{ width: '100%', padding: '0px', marginBottom: '5px' }}
            />
            <CardContainer allticketced={allticketced} searchTerm={searchTerm} />
        </div>
    );
};

export default HistorialTicket;
