import React, { useState, useEffect } from 'react';
import { Calendar as BigCalendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { newAgenda, getAgenda, delAgenda } from '../../redux/actions';
import styles from './Calendar.module.css';
import { FaTrash } from 'react-icons/fa';

moment.locale('es');
const localizer = momentLocalizer(moment);

const Calendar = () => {
    const dispatch = useDispatch();
    const usuario = useSelector(state => state.usuario);
    const agendas = useSelector(state => state.agenda);

    const [events, setEvents] = useState([]);
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [selectedDate, setSelectedDate] = useState(null);
    const [startTime, setStartTime] = useState('');
    const [endTime, setEndTime] = useState('');
    const [selectedLugar, setSelectedLugar] = useState('');
    const [error, setError] = useState('');
    const [cancelConfirmation, setCancelConfirmation] = useState(false);
    const [cancelingEventId, setCancelingEventId] = useState(null);
    const [cancelSuccessMessage, setCancelSuccessMessage] = useState('');

    const [auxiliar, setAuxiliar] = useState(false);
    const [videoBeams, setVideoBeams] = useState(false);
    const [extensiones, setExtensiones] = useState(false);
    const [otro, setOtro] = useState('');

    useEffect(() => {
        dispatch(getAgenda());
    }, [dispatch]);

    useEffect(() => {
        const mappedEvents = agendas.map(agenda => ({
            id: agenda.id,
            start: new Date(agenda.horaini),
            end: new Date(agenda.horafin),
            title: `Lugar: ${agenda.lugar}  Responsable: ${agenda.Usuario.nombre}`,
            UsuarioId: agenda.UsuarioId,
        }));
        setEvents(mappedEvents);
    }, [agendas]);

    const handleSelectSlot = ({ start }) => {
        setSelectedDate(start);
        setModalIsOpen(true);
        setError('');
    };

    const handleAddEvent = () => {
        if (!selectedLugar || !startTime || !endTime) {
            setError('Faltan datos para poder agendar. Por favor, complete todos los campos.');
            return;
        }

        const start = new Date(selectedDate);
        start.setHours(startTime.split(':')[0], startTime.split(':')[1]);

        const end = new Date(selectedDate);
        end.setHours(endTime.split(':')[0], endTime.split(':')[1]);

        if (start.getHours() < 6 || end.getHours() > 20 || end <= start) {
            setError('Las horas deben estar entre 6:00 am y 8:00 pm, y la hora de fin debe ser posterior a la hora de inicio.');
            return;
        }

        const overlappingEvent = events.find(event =>
            event.title.includes(`Lugar: ${selectedLugar}`) && (
                (start >= event.start && start < event.end) ||
                (end > event.start && end <= event.end) ||
                (start <= event.start && end >= event.end)
            )
        );

        if (overlappingEvent) {
            setError('Ya hay una agenda en las horas seleccionadas para el lugar seleccionado. Por favor, escoja una nueva hora.');
            return;
        }

        const newEvent = {
            horaini: start.toISOString(),
            horafin: end.toISOString(),
            lugar: selectedLugar,
            UsuarioId: usuario.usuario.id,
            nombreUsuario: usuario.usuario.nombre,
            correoUsuario: usuario.usuario.email,
            auxiliar,
            videoBeams,
            extensiones,
            otro,
        };

        dispatch(newAgenda(newEvent)).then(() => {
            setEvents([
                ...events,
                {
                    id: newEvent.id,
                    start,
                    end,
                    title: `Lugar: ${selectedLugar}  Responsable: ${usuario.usuario.nombre}`,
                    UsuarioId: usuario.usuario.id,
                },
            ]);
            setModalIsOpen(false);
        });
    };

    const handleCancelConfirmation = (eventId) => {
        setCancelConfirmation(true);
        setCancelingEventId(eventId);
    };

    const handleCancelEvent = () => {
        dispatch(delAgenda(cancelingEventId)).then(() => {
            setEvents(events.filter(event => event.id !== cancelingEventId));
            setCancelConfirmation(false);
            setCancelSuccessMessage('Cancelado con éxito');
            setTimeout(() => {
                setCancelSuccessMessage('');
            }, 3000);
        }).catch(error => {
            console.error('Error al cancelar evento:', error);
            setCancelConfirmation(false);
            alert('Hubo un error al intentar cancelar el evento.');
        });
    };

    const getEventsForSelectedDate = () => {
        return events.filter(
            event =>
                moment(event.start).isSame(selectedDate, 'day') &&
                moment(event.end).isSame(selectedDate, 'day')
        );
    };

    return (
        <div>
            <BigCalendar
                localizer={localizer}
                events={events}
                startAccessor="start"
                endAccessor="end"
                selectable
                onSelectSlot={handleSelectSlot}
                style={{ height: 550 }}
                views={['month']}
            />
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={() => setModalIsOpen(false)}
                style={{
                    content: {
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        right: 'auto',
                        bottom: 'auto',
                        marginRight: '-50%',
                        transform: 'translate(-50%, -50%)',
                        backgroundColor: '#fff',
                        padding: '20px',
                        borderRadius: '8px',
                        outline: 'none',
                        width: '700px',
                        maxWidth: '90%',
                        boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
                        maxHeight: '80vh',
                        overflowY: 'auto',
                    },
                    overlay: {
                        backgroundColor: 'rgba(0, 0, 0, 0.75)',
                        zIndex: 1000,
                    },
                }}
                contentLabel="Seleccionar Rango de Horas"
            >
                <h2>Agendas para {selectedDate && moment(selectedDate).format('YYYY-MM-DD')}</h2>
                <div className={styles.modalContent}>
                    <div className={styles.column}>
                        <div className={styles.inputGroup}>
                            <label>Hora de inicio:</label><br />
                            <input
                                type="time"
                                value={startTime}
                                onChange={(e) => setStartTime(e.target.value)}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Hora de fin:</label><br />
                            <input
                                type="time"
                                value={endTime}
                                onChange={(e) => setEndTime(e.target.value)}
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label>Lugar:</label><br />
                            <select
                                value={selectedLugar}
                                onChange={(e) => setSelectedLugar(e.target.value)}
                            >
                                <option value="">Seleccione un lugar</option>
                                <option value="Sala Juntas">Sala Juntas</option>
                                <option value="Sala Capacitación">Sala Capacitación</option>
                                <option value="Cafeteria">Cafeteria</option>
                            </select>
                        </div>
                    </div>
                    <div className={styles.column}>
                        <label><strong>Recursos adicionales:</strong></label>
                        <div className={styles.checkboxContainer}>
                            <label className={styles.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={auxiliar}
                                    onChange={(e) => setAuxiliar(e.target.checked)}
                                />
                                Auxiliar cafetería
                            </label>
                            <label className={styles.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={videoBeams}
                                    onChange={(e) => setVideoBeams(e.target.checked)}
                                />
                                Video beams
                            </label>
                            <label className={styles.checkboxLabel}>
                                <input
                                    type="checkbox"
                                    checked={extensiones}
                                    onChange={(e) => setExtensiones(e.target.checked)}
                                />
                                Extensiones eléctricas
                            </label>
                            <label className={styles.checkboxLabel}>
                                <input
                                    type="text"
                                    value={otro}
                                    onChange={(e) => setOtro(e.target.value)}
                                    placeholder="Otro"
                                />
                            </label>
                        </div>
                    </div>
                </div>
                {error && <p style={{ color: 'red' }}>{error}</p>}
                {cancelConfirmation && (
                    <Modal
                        isOpen={true}
                        style={{
                            content: {
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                right: 'auto',
                                bottom: 'auto',
                                marginRight: '-50%',
                                transform: 'translate(-50%, -50%)',
                                backgroundColor: '#fff',
                                padding: '20px',
                                borderRadius: '8px',
                                outline: 'none',
                                maxWidth: '90%',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            },
                            overlay: {
                                backgroundColor: 'rgba(0, 0, 0, 0.75)',
                                zIndex: 1000,
                            },
                        }}
                        contentLabel="Confirmación de cancelación"
                    >
                        <h2>¿Estás seguro de que quieres cancelar este evento?</h2>
                        <button onClick={handleCancelEvent}>Sí</button>
                        <button onClick={() => setCancelConfirmation(false)}>No</button>
                    </Modal>
                )}
                <div className={styles.buttons}>
                    <button onClick={handleAddEvent}>Agendar Sala</button>
                    {/* <button onClick={() => setModalIsOpen(false)}>Cancelar</button> */}
                </div>
                <div className={styles.eventsList}>
                    <h3>Eventos programados para este día:</h3>
                    {getEventsForSelectedDate().map(event => (
                        <div key={event.id} className={styles.eventItem}>
                            <div className={styles.eventDetails}>
                                <span>{moment(event.start).format('HH:mm')} - {moment(event.end).format('HH:mm')}</span>
                                <span>{event.title}</span>
                            </div>
                            {event.UsuarioId === usuario.usuario.id && (
                                <FaTrash onClick={() => handleCancelConfirmation(event.id)} className={styles.deleteIcon} />
                            )}
                        </div>
                    ))}
                </div>
                {cancelSuccessMessage && (
                    <p className={styles.successMessage}>{cancelSuccessMessage}</p>
                )}
            </Modal>
        </div>
    );
};

export default Calendar;
