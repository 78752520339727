import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAllHistorial } from '../../../redux/actions';
import CardContainer from "../../../view/Tickets/HistorialTicket/CardsContainer";

const HistorialTicket = () => {
  const dispatch = useDispatch();
  const usuario = useSelector((state) => state.usuario);
  const allticketced = useSelector((state) => state.allticketced);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (usuario && usuario.usuario && usuario.usuario.cedula) {
      dispatch(getAllHistorial(usuario.usuario.cedula))
        .then(() => {
          setLoading(false);
        })
        .catch((err) => {
          setLoading(false);
          if (err.response && err.response.status === 404) {
            setError('Usuario no tiene historial de tickets');
          } else {
            setError('Error al cargar los tickets');
          }
        });
    } else {
      setLoading(false);
      setError('Usuario no tiene historial de tickets');
    }
  }, [dispatch, usuario]);

  return (
    <div>
      <h2>Historial De Servicios</h2>
      {loading ? (
        <div className="spinner-container">
          {/* Puedes agregar un spinner si lo deseas */}
          Cargando...
        </div>
      ) : (
        error ? (
          <div className="alert alert-danger">{error}</div>
        ) : (
          allticketced && allticketced.tickets && allticketced.tickets.length > 0 ? (
            <CardContainer allticketced={allticketced.tickets} usuario={usuario.usuario} />
          ) : (
            <p>No hay tickets para mostrar.</p>
          )
        )
      )}
    </div>
  );
};

export default HistorialTicket;
