import React from "react";
import Card from "../HistorialTicket/Card";
import styles from "./CardContainer.module.css";

export default function CardContainer({ allticketced, usuario }) {
  // console.log("Carcontainer::", allticketced);

  if (!Array.isArray(allticketced) || allticketced.length === 0) {
    // console.log("No tickets to display");
    return <p>No hay tickets para mostrar.</p>;
  }

  // Filtrar los tickets que tienen EstadoId igual a 4, 5, o 6
  const filteredTickets = allticketced.filter(
    (ticket) => ticket.EstadoId === 5 || ticket.EstadoId === 6
  );

  // console.log("Filtered Tickets:", filteredTickets);

  return (
    <div className={styles.container}>
      <div className={styles.container1}>
        {filteredTickets.map((ticket, index) => {
          // console.log(`Mapping data index ${index}:`, ticket);
          if (!ticket) {
            // console.error(`Ticket at index ${index} is undefined.`);
            return null;
          }
          return (
            <Card key={ticket.id} ticket={{ ...ticket, nombre: usuario.nombre }} />
          );
        })}
      </div>
    </div>
  );
}
