import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import styles from './Tecnico.module.css'; // Importar estilos modulares

const Tecnico = () => {
    const [equiposOpen, setEquiposOpen] = useState(false);
    const [usuariosOpen, setUsuariosOpen] = useState(false);
    const [ticketsOpen, setTicketsOpen] = useState(false);

    const toggleEquipos = () => {
        setEquiposOpen(!equiposOpen);
        if (usuariosOpen) setUsuariosOpen(false); // Cerrar el panel de Usuarios si está abierto
        if (ticketsOpen) setTicketsOpen(false); // Cerrar el panel de Tickets si está abierto
    };

    const toggleUsuarios = () => {
        setUsuariosOpen(!usuariosOpen);
        if (equiposOpen) setEquiposOpen(false); // Cerrar el panel de Equipos si está abierto
        if (ticketsOpen) setTicketsOpen(false); // Cerrar el panel de Tickets si está abierto
    };

    const toggleTickets = () => {
        setTicketsOpen(!ticketsOpen);
        if (equiposOpen) setEquiposOpen(false); // Cerrar el panel de Equipos si está abierto
        if (usuariosOpen) setUsuariosOpen(false); // Cerrar el panel de Usuarios si está abierto
    };

    return (
        <div className={styles.sidebarWrapper}>
            <h2 className={styles.sidebarHeading}>Opciones Técnicas</h2>

            <div className={`accordion ${styles.accordion}`} id="accordionMenu">
                {/* Panel de Gestión de Tickets */}
                <div className={`accordion-item ${styles.accordionItem}`}>
                    <h2 className={`accordion-header ${styles.accordionHeader}`} id="headingTicket">
                        <button className={`accordion-button ${styles.accordionButton}`} type="button" onClick={toggleTickets}>
                            Gestión de Tickets
                        </button>
                    </h2>
                    <div className={`${ticketsOpen ? "accordion-collapse collapse show" : "accordion-collapse collapse"} ${styles.accordionCollapse}`} aria-labelledby="headingTicket" data-bs-parent="#accordionMenu">
                        <div className={`accordion-body ${styles.accordionBody}`}>
                            <ul className={`list-group list-group-flush ${styles.listGroup}`}>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/asigtec">Asignar técnico</Link></li>
                                {/* <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/resolver-ticket">Resolver Ticket</Link></li> */}
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/allhistorial">Historial de servicios</Link></li>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/home">Indicadores</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Panel de Equipos */}
                <div className={`accordion-item ${styles.accordionItem}`}>
                    <h2 className={`accordion-header ${styles.accordionHeader}`} id="headingEquipos">
                        <button className={`accordion-button ${styles.accordionButton}`} type="button" onClick={toggleEquipos}>
                            Equipos
                        </button>
                    </h2>
                    <div className={`${equiposOpen ? "accordion-collapse collapse show" : "accordion-collapse collapse"} ${styles.accordionCollapse}`} aria-labelledby="headingEquipos" data-bs-parent="#accordionMenu">
                        <div className={`accordion-body ${styles.accordionBody}`}>
                            <ul className={`list-group list-group-flush ${styles.listGroup}`}>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/addequipo">Crear Equipo</Link></li>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/asignar-equipo">Asignar Equipo</Link></li>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/dar-de-baja">Dar De Baja</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>

                {/* Panel de Usuarios */}
                <div className={`accordion-item ${styles.accordionItem}`}>
                    <h2 className={`accordion-header ${styles.accordionHeader}`} id="headingUsuarios">
                        <button className={`accordion-button ${styles.accordionButton}`} type="button" onClick={toggleUsuarios}>
                            Usuarios
                        </button>
                    </h2>
                    <div className={`${usuariosOpen ? "accordion-collapse collapse show" : "accordion-collapse collapse"} ${styles.accordionCollapse}`} aria-labelledby="headingUsuarios" data-bs-parent="#accordionMenu">
                        <div className={`accordion-body ${styles.accordionBody}`}>
                            <ul className={`list-group list-group-flush ${styles.listGroup}`}>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/adduser">Crear Usuario</Link></li>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/cargos">Cargos</Link></li>
                                <li className={`list-group-item ${styles.listGroupItem}`}><Link to="/roles">Roles</Link></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Tecnico;
