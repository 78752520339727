import React from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../Sidebar/Sidebar';
import 'bootstrap/dist/css/bootstrap.min.css';
import './Layout.style.css'; // Ensure to create and use this CSS file

const Layout = () => {
    return (
        <div className="d-flex flex-column flex-md-row">
            <Sidebar />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Layout;
