import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { CedEstaTicket } from '../../redux/actions';
import CardContainer from "../../Components/CardsContainer/CardsContainer";
import PacmanLoader from 'react-spinners/PacmanLoader';

const SeguimientoTicket = () => {
    const dispatch = useDispatch();
    const usuario = useSelector((state) => state.usuario);
    const estadoticket = useSelector((state) => state.estadoticket);
    const [error, setError] = useState('');
    const [loading, setLoading] = useState(true);
    const [showCards, setShowCards] = useState(false);

    useEffect(() => {
        if (usuario.usuario && usuario.usuario.cedula) {
            dispatch(CedEstaTicket(usuario.usuario.cedula))
                .then(() => {
                    setTimeout(() => {
                        setLoading(false);
                        setShowCards(true);
                    }, 1000); // 2 seconds delay
                })
                .catch((err) => {
                    setLoading(false);
                    if (err.response && err.response.status === 404) {
                        setError('Usuario no tiene tickets generados o por cerrar');
                    } else {
                        setError('Error al cargar los tickets');
                    }
                });
        } else {
            setLoading(false);
            setError('Usuario no tiene tickets generados');
        }
    }, [dispatch, usuario]);

    return (
        <div>
            <h2>Seguimiento Ticket</h2>
            {loading ? (
                <div className="spinner-container">
                    <PacmanLoader color="#3559d1" loading={true} margin={12} size={40} />
                </div>
            ) : (
                error ? (
                    <div className="alert alert-danger">{error}</div>
                ) : (
                    showCards && <CardContainer estadoticket={estadoticket} />
                )
            )}
        </div>
    );
};

export default SeguimientoTicket;
