import React from "react";
import Card from "../Asignartecnico/Card";
import style from "./CardsContainer.module.css";

export default function CardContainer({ asignartec }) {
  if (!asignartec || !Array.isArray(asignartec) || asignartec.length === 0) {
    return null;
  }

  const getEstadoName = (EstadoId) => {
    switch (EstadoId) {
      case 1:
        return "Creado";
      case 2:
        return "Asignado y en proceso";
      case 3:
        return "Pendiente";
      default:
        return "Desconocido";
    }
  };

  const filteredTickets = asignartec.reduce((acc, ticket) => {
    if (!acc[ticket.TicketId] || new Date(ticket.fecha) > new Date(acc[ticket.TicketId].fecha)) {
      acc[ticket.TicketId] = {
        ...ticket,
        Estado: getEstadoName(ticket.EstadoId)
      };
    }
    return acc;
  }, {});

  const ticketArray = Object.values(filteredTickets);

  // console.log("EMAILLLLL",ticketArray)
 

  return (
    <div className={style.container}>
      <div className={style.container1}>
        {ticketArray.map((ticketData) => (
          <Card key={ticketData.TicketId} ticketData={ticketData} equipoCod={ticketData.Tecniconombre} email={ticketData.email} />
        ))}
      </div>
    </div>
  );
}
