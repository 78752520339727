import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

const PrivateRoute = ({ element: Component, roles }) => {
    const token = useSelector(state => state.token);
    const usuario = useSelector(state => state.usuario);
    let storedToken;

    // Intentar parsear el token almacenado en sessionStorage
    try {
        storedToken = JSON.parse(sessionStorage.getItem('token'));
    } catch (error) {
        console.error("Error parsing stored token:", error);
        storedToken = null;
    }

    // Verificar si el token es válido y si el roleId del usuario está permitido
    const isTokenValid = (token && token.message === "Token válido") || (storedToken && storedToken.message === "Token válido");
    const isRoleAllowed = usuario && usuario.usuario && roles.includes(usuario.usuario.rolid);

    return isTokenValid && isRoleAllowed ? <Component /> : <Navigate to="/login" />;
};

export default PrivateRoute;
