import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
const apiUrl = "https://hdesk.incolabcolombia.com.co"
// const apiUrl = "http://localhost:3001"
// const apiUrl = "http://10.0.2.93:3001";

export const GET_USERS = "GET_USERS";
export const GET_TOKEN = "GET_TOKEN";
export const TOKEN_ERROR = "TOKEN_ERROR";
export const LOGOUT = 'LOGOUT';
export const GET_TIPOFALLA = 'GET_TIPOFALLA';
export const GET_ASIGUSUARIO = 'GET_ASIGUSUARIO';
export const GET_SEGUITICKET = 'GET_SEGUITICKET'; 
export const GET_TICKHISTORIAL='GET_TICKHISTORIAL'
export const ID_TICKET_HISTORIAL_SUCCESS = 'ID_TICKET_HISTORIAL_SUCCESS';
export const UPDATE_TICKET_STATUS_SUCCESS = 'UPDATE_TICKET_STATUS_SUCCESS';
export const UPDATE_TICKET_STATUS_FAILURE = 'UPDATE_TICKET_STATUS_FAILURE';
export const GET_POSTREGISTROS = 'GET_POSTREGISTROS';
export const NEW_AGENDA = 'NEW_AGENDA';
export const GET_AGENDA = 'GET_AGENDA';
export const DELETE_AGENDA = 'DELETE_AGENDA'
export const DELETE_AGENDA_ERROR = 'DELETE_AGENDA_ERROR'
export const TICKET_ASIGNAR = 'TICKET_ASIGNAR'
export const TECNICO_TI = 'TECNICO_TI'
export const NEW_REG = 'NEW_REG'
export const GET_USERS_ERROR = "GET_USERS_ERROR"; // Nueva constante
export const GET_ALLTICKETCED = "GET_ALLTICKETCED"
export const POST_RESOLUCION = 'POST_RESOLUCION'
export const GET_RESOLUCION = 'GET_RESOLUCION'
export const GET_ALLTICKUSER = 'GET_ALLTICKUSER'
export const GET_TODOSUSER = 'GET_TODOSUSER'
export const GET_CARGO = 'GET_CARGO'
export const GET_AREA = 'GET_AREA'
export const GET_ROL = 'GET_ROL'
export const GET_EQUIPOS = 'GET_EQUIPOS'
export const GET_SO = 'GET_SO'
export const GET_MARCA = 'GET_MARCA'
export const GET_TIPOEQUI = 'GET_TIPOEQUI'
export const GET_CRITICI = 'GET_CRITICI'

export const crearTicket = createAsyncThunk(
  'ticket/crearTicket',
  async (ticketData, thunkAPI) => {
    try {
      const response = await axios.post(`${apiUrl}/crearticket`, ticketData);
      return response.data; // Devolver la respuesta del servidor
    } catch (error) {
      // Manejar el error y devolverlo usando rejectWithValue para que Redux Toolkit lo maneje
      return thunkAPI.rejectWithValue(error.response.data);
    }
  }
);

export const getUsuarios = (cedula) => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/getusua/${cedula}`);
      sessionStorage.setItem('usuario', JSON.stringify(response.data));
      return dispatch({
        type: GET_USERS,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.data) {
        return dispatch({
          type: GET_USERS_ERROR,
          payload: error.response.data.error
        });
      }
      console.error("Error fetching user:", error.message);
    }
  }
};

export const validateToken = (token) => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/verify/${token}`);
      if (response.data) {
        sessionStorage.setItem('token', JSON.stringify(response.data));
        return dispatch({
          type: GET_TOKEN,
          payload: response.data
        });
      } else {
        return dispatch({
          type: TOKEN_ERROR,
          payload: response.data
        });
      }
    } catch (error) {
      console.error("There was an error validating the token!", error.message);
      return dispatch({
        type: TOKEN_ERROR,
        payload: 'Error validating token'
      });
    }
  }
};

export const logout = () => {
  sessionStorage.removeItem('token');
  sessionStorage.removeItem('usuario'); // Limpia el usuario de sessionStorage
  return {
    type: LOGOUT
  };
};

export const getTipofalla = () => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/tipfalla`);
    return dispatch({
      type: GET_TIPOFALLA,
      payload: response.data
    });
  }
};

export const CedAsigequipo = (cedula) => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/cedasigna/${cedula}`);
    return dispatch({
      type: GET_ASIGUSUARIO,
      payload: response.data
    });
  }
};

export const CedEstaTicket = (cedula) => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/estaticket/${cedula}`);
      return dispatch({
        type: GET_SEGUITICKET,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
};

export const idTicketHistorial = (ticket) => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/detail/${ticket}`);
    return dispatch({
      type: GET_TICKHISTORIAL,
      payload: response.data
    });
  }
};

export const updateTicketStatus = (registroId, newStatus) => async (dispatch) => {
  try {
      const response = await fetch(`/api/tickets/${registroId}/status`, {
          method: 'PATCH',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({ status: newStatus }),
      });
      const data = await response.json();

      dispatch({
          type: UPDATE_TICKET_STATUS_SUCCESS,
          payload: data,
      });

      dispatch(idTicketHistorial(data.ticketId));
  } catch (error) {
      dispatch({
          type: UPDATE_TICKET_STATUS_FAILURE,
          payload: error.message,
      });
  }
};

export const crearTicketregistro = (ticketregistro) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/registrotick`,ticketregistro);
    return dispatch({
      type: GET_POSTREGISTROS,
      payload: response.data
    });
  }
}

export const newAgenda = (agenda) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/newagenda`,agenda);
    return dispatch({
      type: NEW_AGENDA,
      payload: response.data
    });
  }
}

export const getAgenda = () => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/getagenda`);
    return dispatch({
      type: GET_AGENDA,
      payload: response.data
    });
  }
};

export const delAgenda = (Id) => {
  return async function (dispatch) {
    try {
      await axios.delete(`${apiUrl}/agenda/${Id}`);

      dispatch({
        type: DELETE_AGENDA,
        payload: Id
      });

    } catch (error) {
      console.error('Error al eliminar agenda:', error);

      dispatch({
        type: DELETE_AGENDA_ERROR,
        payload: error.message
      });
    }
  }
};

export const getTicketasignar = () => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/gettickasig`);
    return dispatch({
      type: TICKET_ASIGNAR,
      payload: response.data
    });
  }
};

export const getTecnicoti = () => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/userti`);
    return dispatch({
      type: TECNICO_TI,
      payload: response.data
    });
  }
};

export const newRegistro = (registro) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/registrotick`,registro);
    return dispatch({
      type: NEW_REG,
      payload: response.data
    });
  }
}

export const getAllHistorial = (cedula) => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allhistick/${cedula}`);
      return dispatch({
        type: GET_ALLTICKETCED,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const postResolucion = (resolucion) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/newresolucion`,resolucion);
    return dispatch({
      type: POST_RESOLUCION,
      payload: response.data
    });
  }
}

export const getResolucion = (ticketId) => {
  return async function (dispatch) {
    const response = await axios.get(`${apiUrl}/allresolucion/${ticketId}`);
    return dispatch({
      type: GET_RESOLUCION,
      payload: response.data
    });
  }
}

export const getAllTicketUser = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allticketuser`);
      return dispatch({
        type: GET_ALLTICKUSER,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getAllusuarios = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allusers`);
      return dispatch({
        type: GET_TODOSUSER,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getCargos = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/getcargo`);
      return dispatch({
        type: GET_CARGO,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getArea = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/getarea`);
      return dispatch({
        type: GET_AREA,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getRol = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allrol`);
      return dispatch({
        type: GET_ROL,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const postuser = (user) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/creauser`,user);
    return dispatch({
      type: 'POST_USER_SUCCESS',
      payload: response.data
    });
  }
}
export const postequi = (equipo) =>{
  return async function (dispatch) {
    const response = await axios.post(`${apiUrl}/crearequi`,equipo);
    return dispatch({
      type: 'POST_EQUIPO_SUCCESS',
      payload: response.data
    });
  }
}

export const getEquipo = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/equipos`);
      return dispatch({
        type: GET_EQUIPOS,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getSO = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/so`);
      return dispatch({
        type: GET_SO,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getMarca = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allmarca`);
      return dispatch({
        type: GET_MARCA,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getTipoequipo = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/tipoequipo`);
      return dispatch({
        type: GET_TIPOEQUI,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}

export const getCritici = () => {
  return async function (dispatch) {
    try {
      const response = await axios.get(`${apiUrl}/allcritici`);
      return dispatch({
        type: GET_CRITICI,
        payload: response.data
      });
    } catch (error) {
      if (error.response && error.response.status === 404) {
        return Promise.reject({ response: { status: 404 } }); // Devuelve un objeto de error personalizado
      }
      return Promise.reject(error);
    }
  }
}