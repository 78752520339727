import React from "react";
import { format } from "date-fns"; // Importa la función format de date-fns
import styles from "./Card.module.css";

function Card({ ticket }) {
  if (!ticket) {
    // console.error("Ticket is undefined.");
    return null;
  }

  const getEstadoDescripcion = (estadoId) => {
    switch (estadoId) {
      case 5:
        return "Cerrado";
      case 6:
        return "Cancelado";
      default:
        return estadoId || "N/A";
    }
  };

  const estadoClass = ticket.EstadoId === 5 ? styles.cerrado : ticket.EstadoId === 6 ? styles.cancelado : "";

  // Formatea la fecha
  const formatFecha = (fecha) => {
    return format(new Date(fecha), "yyyy-MM-dd HH:mm:ss");
  };

  return (
    <div className={`${styles.card} ${estadoClass}`}>
      <div className={styles.cardContent}>
        <div className={styles.title}>
          <p className={styles.info}>
            <strong>Ticket #:</strong> {ticket.TicketId || "N/A"}
          </p>
        </div>
        <p className={styles.info}>
          <strong>Fecha Última Actualización:</strong> {formatFecha(ticket.fecha) || "N/A"}
        </p>
        <p className={styles.info}>
          <strong>Estado Ticket:</strong> {getEstadoDescripcion(ticket.EstadoId)}
        </p>
        <p className={styles.info}>
          <strong>Observación:</strong> {ticket.observacion || "N/A"}
        </p>
        <p className={styles.info}>
          <strong>Usuario:</strong> {ticket.nombre || "N/A"}
        </p>
        <p className={styles.info}>
          <strong>Técnico:</strong> {ticket.UsuarioId || "Sin Asignar"}
        </p>
      </div>
    </div>
  );
}

export default Card;
