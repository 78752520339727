import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from "./view/Loging/Login";
import Home from "./view/Home/Home";
import PrivateRoute from '../src/Components/PrivateRoute';
import GenerarTicket from './view/Tickets/GenerarTicket';
import SeguimientoTicket from './view/Tickets/SeguimientoTicket';
import HistorialServicios from './view/Tickets/HistorialServicios';
import Layout from './Components/Layout/Layout';
import TicketHistorial from './view/Detail/Detail';
import Tecnico from './view/Tickets/Tecnico';
import Asignartec from './Components/Gesticket/Asignartecnico/Asignartecnico'
import Resolverticket from './Components/Gesticket/Resticket/Resolverticket';
import HistorialTicket from './view/Tickets/HistorialTicket/HistorialTicket';
import Cerarticket from './Components/Gesticket/Cerrarticket/Cerrarticket'
import { useSelector } from 'react-redux';
import Cerrarticket from './Components/Gesticket/Cerrarticket/Cerrarticket';
import Agreuser from './Components/Usuarios/Crearusuario/Crearusuario';
import Gettickalluser from './Components/Gesticket/HistorServicios/HistorialServAll'
import GetEquipo from './Components/Equipos/Crearequipos/Crearequipos'


function App() {
    const usuario = useSelector(state => state.usuario);

    // Verifica si usuario.usuario está definido antes de acceder a rolid
    const userRoleId = usuario.usuario ? usuario.usuario.rolid : null;

    return (
        <div className="App">
            
                <Routes>
                    <Route path="/login" element={<Login />} />
                    <Route path="/" element={<PrivateRoute element={Layout} roles={[1, 2]} />}>
                        <Route path="/home" element={<Home />} />
                        <Route path="/generar-ticket" element={<GenerarTicket />} />
                        <Route path="/seguimiento-ticket" element={<SeguimientoTicket />} />
                        <Route path="/agendamiento" element={<HistorialServicios />} />
                        <Route path="/detail/:ticket" element={<TicketHistorial />} />
                        <Route path="/histtick" element={<HistorialTicket />} />
                        <Route path="/cerrartick" element={<Cerrarticket />} />
                        {userRoleId === 1 && <Route path="/tecnico" element={<Tecnico />} />}
                        {userRoleId === 1 && <Route path="/asigtec" element={<Asignartec />} />}
                        {userRoleId === 1 && <Route path="/resoltick/:ticketId" element={<Resolverticket />} />}
                        {userRoleId === 1 && <Route path="/allhistorial/" element={<Gettickalluser />} />}
                        {userRoleId === 1 && <Route path="/adduser" element={<Agreuser/>} />}
                        {userRoleId === 1 && <Route path="/addequipo" element={<GetEquipo/>} />}
                    </Route>
                    <Route path="*" element={<Navigate to="/login" />} />
                </Routes>
          
        </div>
    );
}

export default App;
