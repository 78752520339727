import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { format } from "date-fns";
import { useDispatch, useSelector } from "react-redux";
import { idTicketHistorial, crearTicketregistro, getResolucion } from "../../redux/actions";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import styles from "./TicketDetailModal.module.css";

const MySwal = withReactContent(Swal);

function TicketModal({ show, onHide, ticketId }) {
    const dispatch = useDispatch();
    const historial = useSelector((state) => state.tickhistorial);
    const usuario = useSelector((state) => state.usuario);
    const navigate = useNavigate();
    const [newStatus, setNewStatus] = useState("");
    const [observacion, setObservacion] = useState("");
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (ticketId) {
            dispatch(idTicketHistorial(ticketId));
        }
    }, [dispatch, ticketId]);

    const formatFecha = (fecha) => {
        return format(new Date(fecha), "dd/MM/yyyy HH:mm:ss");
    };

    const handleStatusChange = (e) => {
        setNewStatus(e.target.value);
    };

    const handleObservacionChange = (e) => {
        setObservacion(e.target.value);
    };

    const handleUpdateStatus = async (registroId) => {
        if (newStatus && observacion) {
            const fechaActual = new Date();
            const formattedFecha = format(fechaActual, "yyyy-MM-dd HH:mm:ss");

            const data = {
                fecha: formattedFecha,
                Observacion: observacion,
                TicketId: historial.registros[0].TicketId,
                EstadoId: newStatus === "Cancelado" ? 6 : null,
                nombreUsuario: usuario.usuario.nombre,
                correoUsuario: usuario.usuario.email,
            };

            setLoading(true);

            try {
                await dispatch(crearTicketregistro(data));
                MySwal.fire({
                    title: 'Actualización Exitosa',
                    text: 'El ticket ha sido actualizado exitosamente.',
                    icon: 'success',
                    confirmButtonText: 'Aceptar'
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigate("/seguimiento-ticket");
                        window.location.reload(); // Forzar actualización de la página
                    }
                });
                setNewStatus("");
                setObservacion("");
                onHide();
            } catch (error) {
                setLoading(false);
                MySwal.fire({
                    title: 'Error',
                    text: 'Hubo un error al actualizar el ticket.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        } else {
            alert("La observación es obligatoria.");
        }
    };

    const handleCloseTicket = async () => {
        if (ticketId) {
            try {
                const resolucionData = await dispatch(getResolucion(ticketId));
                navigate("/cerrartick", {
                    state: {
                        ticketId,
                        resolucionDetails: resolucionData,
                    },
                });
                onHide(); // Cierra el modal
            } catch (error) {
                MySwal.fire({
                    title: 'Error',
                    text: 'Error al obtener detalles de resolución.',
                    icon: 'error',
                    confirmButtonText: 'Aceptar'
                });
            }
        }
    };

    if (!show || !historial || !historial.registros || historial.registros.length === 0) {
        return null;
    }

    const allCreado = historial.registros.every(
        (registro) => registro.estadoDescripcion === "Creado"
    );

    return (
        <>
            <Modal show={show} onHide={onHide} centered>
                <Modal.Header closeButton>
                    <Modal.Title className={styles.modalTitle}>
                        Detalles del Ticket # {historial.registros[0].TicketId}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className={styles.modalBody}>
                    {historial.registros.map((registro) => (
                        <div key={registro.id} className={styles.registroItem}>
                            <p><strong>Estado:</strong> {registro.estadoDescripcion}</p>
                            <p><strong>Fecha última actualización:</strong> {formatFecha(registro.fecha)}</p>
                            <p className={styles.observacion}>
                                <strong>Observación:</strong> {registro.observacion}
                            </p>
                            {registro.estadoDescripcion === "Resuelto" && (
                                <Button
                                    variant="success"
                                    size="sm"
                                    onClick={handleCloseTicket}
                                >
                                    Cerrar Ticket
                                </Button>
                            )}
                        </div>
                    ))}
                    {allCreado && (
                        <div className={styles.updateSection}>
                            <Form.Group controlId="statusSelect">
                                <Form.Label>Cancelar Este Ticket:</Form.Label>
                                <Form.Control
                                    as="select"
                                    value={newStatus}
                                    onChange={handleStatusChange}
                                >
                                    <option value="" disabled>Seleccione una opción</option>
                                    <option value="Cancelado">Cancelar Ticket</option>
                                </Form.Control>
                            </Form.Group>
                            {newStatus === "Cancelado" && (
                                <>
                                    <Form.Group controlId="observacionInput">
                                        <Form.Label>Observación</Form.Label>
                                        <Form.Control
                                            as="textarea"
                                            rows={3}
                                            value={observacion}
                                            onChange={handleObservacionChange}
                                            placeholder="Escriba el motivo de la cancelación"
                                        />
                                    </Form.Group>
                                    <Button
                                        variant="primary"
                                        onClick={() => handleUpdateStatus(historial.registros[0].id)}
                                    >
                                        Actualizar
                                    </Button>
                                </>
                            )}
                        </div>
                    )}
                </Modal.Body>
                <Modal.Footer className={styles.modalFooter}>
                    <Button variant="secondary" onClick={onHide}>
                        Cerrar
                    </Button>
                </Modal.Footer>
            </Modal>
            
        </>
    );
}

export default TicketModal;
