import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FaArrowLeft } from 'react-icons/fa';
import { useDispatch, useSelector } from 'react-redux';
import { getResolucion } from '../../../redux/actions';
import { newRegistro } from '../../../redux/actions';
import styles from './Cerrarticket.module.css';
// import Satisfaccion from '../../Satisfaccion/Satisfaccion';
import { format } from 'date-fns';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

const Cerrarticket = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { ticketId, nombre, fecha, tecnico } = location.state;

    const [value, setValue] = useState(0); // Estado para el valor de satisfacción
    const [observaciones, setObservaciones] = useState(''); // Estado para las observaciones
    const [alertMessage, setAlertMessage] = useState('');
    // console.log("SATISFA", value)
    // Función para manejar cambios en la satisfacción
    const handleSatisfaccionChange = (newValue) => {
        setValue(newValue);
        // Inicializar observaciones cuando se elija una satisfacción
        if (newValue > 0 && observaciones === '') {
            setObservaciones('');
        }
    };

    // Función para manejar cambios en las observaciones
    const handleObservacionesChange = (event) => {
        setObservaciones(event.target.value);
    };


    const resolucion = useSelector((state) => state.resolucion);
    const usuario = useSelector((state) => state.usuario);
    // console.log("USERJAJA", usuario)
    // console.log("SOLUCION", resolucion)
    const [formData, setFormData] = useState({
        observaciones: '',
        'Limpieza interna': false,
        'Revisión y limpieza de virus': false,
        'Particionamiento y formateo de disco': false,
        'Instalación de controladores': false,
        'Instalación actualizaciones - software utilitario': false,
        'Configuración correo electrónico': false,
        'Revisión del sistema de archivo': false,
        'Limpieza de temporales': false,
        'Instalación de SO': false,
        'Instalación actualizaciones So': false,
        'Configuración de red': false,
        'Configuración impresora, local o red': false,
        'Disco Duro - SATA': false,
        'Disco Duro - SSD': false,
        'Memoria RAM': false,
        'Batería': false,
        'Cargador': false,
        'Tarjeta Madre': false,
        'Procesador': false,
        'Tarjeta de Video': false,
        'Tarjeta de red LAN': false,
        'Tarjeta de red WLAN': false,
        'Pantalla': false,
        'Fuente de Poder': false,
        'Batería interna 3v': false,
        'Teclado': false,
        'Mouse': false,
        'Combo inalámbrico': false,
        'Diadema': false,
        'Adaptador RJ45 - USB': false,
        'Multipuerto USB': false,
        'Funda Para Portátil': false,
        'Pad Mouse': false,
        'Otro': false,
        software_so: '',
        software_office: '',
        software_adobe_reader: '',
        software_firefox: '',
        software_otro: '',
        software_chrome: '',
        software_acrobat: '',
        software_codec: '',
        software_zip: '',
        software_otro2: ''
    });

    useEffect(() => {
        if (ticketId) {
            dispatch(getResolucion(ticketId));
        }
    }, [dispatch, ticketId]);

    useEffect(() => {
        if (resolucion && resolucion.data) {
            const { resolucionDetails } = resolucion.data;
            setFormData(prevState => ({
                ...prevState,
                observaciones: resolucion.data.ticketDetails.observacion || '',
                software_so: resolucionDetails.sisope || '',
                software_office: resolucionDetails.suiteoffi || '',
                software_adobe_reader: resolucionDetails.adoberead || '',
                software_firefox: resolucionDetails.mozilla || '',
                software_otro: resolucionDetails.otroinst || '',
                software_chrome: resolucionDetails.goochrom || '',
                software_acrobat: resolucionDetails.adobeacro || '',
                software_codec: resolucionDetails.codecklite || '',
                software_zip: resolucionDetails.zip || '',
                software_otro2: resolucionDetails.otroinst2 || '',
                'Limpieza interna': resolucionDetails.limpinter === 1,
                'Revisión y limpieza de virus': resolucionDetails.revilimvirus === 1,
                'Particionamiento y formateo de disco': resolucionDetails.partformat === 1,
                'Instalación de controladores': resolucionDetails.instacontrola === 1,
                'Instalación actualizaciones - software utilitario': resolucionDetails.instasoftutili === 1,
                'Configuración correo electrónico': resolucionDetails.confcorreo === 1,
                'Revisión del sistema de archivo': resolucionDetails.revisisarchi === 1,
                'Limpieza de temporales': resolucionDetails.limptemp === 1,
                'Instalación de SO': resolucionDetails.instalso === 1,
                'Instalación actualizaciones So': resolucionDetails.instalactso === 1,
                'Configuración de red': resolucionDetails.confred === 1,
                'Configuración impresora, local o red': resolucionDetails.confimpres === 1,
                'Disco Duro - SATA': resolucionDetails.discosata === 1,
                'Disco Duro - SSD': resolucionDetails.discossd === 1,
                'Memoria RAM': resolucionDetails.memoriaram === 1,
                'Batería': resolucionDetails.bateria === 1,
                'Cargador': resolucionDetails.cargador === 1,
                'Tarjeta Madre': resolucionDetails.tarjetamadre === 1,
                'Procesador': resolucionDetails.procesador === 1,
                'Tarjeta de Video': resolucionDetails.tarjetavideo === 1,
                'Tarjeta de red LAN': resolucionDetails.tarjetaredlan === 1,
                'Tarjeta de red WLAN': resolucionDetails.tarjetaredwlan === 1,
                'Pantalla': resolucionDetails.pantalla === 1,
                'Fuente de Poder': resolucionDetails.fuentepoder === 1,
                'Batería interna 3v': resolucionDetails.bateria3v === 1,
                'Teclado': resolucionDetails.teclado === 1,
                'Mouse': resolucionDetails.mouse === 1,
                'Combo inalámbrico': resolucionDetails.comboinalambrico === 1,
                'Diadema': resolucionDetails.diadema === 1,
                'Adaptador RJ45 - USB': resolucionDetails.adaptadorrj45usb === 1,
                'Multipuerto USB': resolucionDetails.multipuertousb === 1,
                'Funda Para Portátil': resolucionDetails.fundaparatil === 1,
                'Pad Mouse': resolucionDetails.padmouse === 1,
                'Otro': resolucionDetails.otropieza === 1,
            }));
        }
    }, [resolucion]);

    const handleBack = () => {
        navigate('/seguimiento-ticket');
    };

    const handleChange = (e) => {
        const { name, type, value, checked } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: type === 'checkbox' ? checked : value
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        if (!formData.observaciones.trim()) {
            alert('Las observaciones son obligatorias.');
            return;
        }
        // Aquí puedes manejar el envío del formulario
    };

    const MySwal = withReactContent(Swal);

    const handleCerrar = () => {
        const data = {
            fecha: new Date().toISOString(),
            Observacion: observaciones,
            TicketId: ticketId,
            EstadoId: 5,
            UsuarioId: resolucion.data.ticketDetails.UsuarioId,
            votacion: value,
            tecnico: resolucion.data.ticketDetails.usuarioDetails.nombre
        };

        if (!data.Observacion.trim()) {
            Swal.fire({
                icon: 'error',
                title: 'Error',
                text: 'Debe calificar el servicio e ingresar el motivo de su calificación.'
            });
            return;
        }


        dispatch(newRegistro(data));
        setAlertMessage('El ticket está pendiente.');

        MySwal.fire({
            title: 'Ticket Cerrado',
            text: 'Se ha cerrado exitosamente su ticket.',
            icon: 'success',
            confirmButtonText: 'Aceptar',
            customClass: {
                confirmButton: styles.confirmButton, // Asegúrate de definir el estilo en tu CSS
            }
        }).then(() => {
            navigate('/seguimiento-ticket');
        });
    };


    const formatDateTime = (dateString) => {
        const date = new Date(dateString);
        return isNaN(date) ? 'Fecha no válida' : format(date, 'yyyy-MM-dd HH:mm:ss');
    };

    if (!resolucion) {
        return <p>Cargando...</p>;
    }

    return (
        <div className={styles.container}>
            <FaArrowLeft className={styles.backIcon} onClick={handleBack} />
            <h2>Resolución y cierre de Ticket</h2>
            <fieldset className={styles.fieldset}>
                <legend>Información del Ticket: <strong>{ticketId}</strong></legend>
                <div className={styles.infoRow}>
                    <span><strong>Fecha Solucion:</strong> {formatDateTime(resolucion.data.ticketDetails.fecha)}</span>
                    <span><strong>Técnico:</strong> {resolucion.data.ticketDetails.usuarioDetails.nombre}</span>
                </div>
            </fieldset>
            <form onSubmit={handleSubmit} className={styles.form}>
                <fieldset className={styles.fieldset}>
                    <legend>Actividades Realizadas</legend>
                    <div className={styles.checkboxGroup}>
                        <label>
                            <input
                                type="checkbox"
                                name="Limpieza interna"
                                checked={formData['Limpieza interna']}
                                onChange={handleChange}
                            />
                            Limpieza interna
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Revisión y limpieza de virus"
                                checked={formData['Revisión y limpieza de virus']}
                                onChange={handleChange}
                            />
                            Revisión y limpieza de virus
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Particionamiento y formateo de disco"
                                checked={formData['Particionamiento y formateo de disco']}
                                onChange={handleChange}
                            />
                            Particionamiento y formateo de disco
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Instalación de controladores"
                                checked={formData['Instalación de controladores']}
                                onChange={handleChange}
                            />
                            Instalación de controladores
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Instalación actualizaciones - software utilitario"
                                checked={formData['Instalación actualizaciones - software utilitario']}
                                onChange={handleChange}
                            />
                            Instalación actualizaciones - software utilitario
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Configuración correo electrónico"
                                checked={formData['Configuración correo electrónico']}
                                onChange={handleChange}
                            />
                            Configuración correo electrónico
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Revisión del sistema de archivo"
                                checked={formData['Revisión del sistema de archivo']}
                                onChange={handleChange}
                            />
                            Revisión del sistema de archivo
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Limpieza de temporales"
                                checked={formData['Limpieza de temporales']}
                                onChange={handleChange}
                            />
                            Limpieza de temporales
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Instalación de SO"
                                checked={formData['Instalación de SO']}
                                onChange={handleChange}
                            />
                            Instalación de SO
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Instalación actualizaciones So"
                                checked={formData['Instalación actualizaciones So']}
                                onChange={handleChange}
                            />
                            Instalación actualizaciones So
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Configuración de red"
                                checked={formData['Configuración de red']}
                                onChange={handleChange}
                            />
                            Configuración de red
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Configuración impresora, local o red"
                                checked={formData['Configuración impresora, local o red']}
                                onChange={handleChange}
                            />
                            Configuración impresora, local o red
                        </label>
                    </div>
                </fieldset>
                <fieldset className={styles.fieldset}>
                    <legend>Cambio de Piezas</legend>
                    <div className={styles.checkboxGroup}>
                        <label>
                            <input
                                type="checkbox"
                                name="Disco Duro - SATA"
                                checked={formData['Disco Duro - SATA']}
                                onChange={handleChange}
                            />
                            Disco Duro - SATA
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Disco Duro - SSD"
                                checked={formData['Disco Duro - SSD']}
                                onChange={handleChange}
                            />
                            Disco Duro - SSD
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Memoria RAM"
                                checked={formData['Memoria RAM']}
                                onChange={handleChange}
                            />
                            Memoria RAM
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Batería"
                                checked={formData['Batería']}
                                onChange={handleChange}
                            />
                            Batería
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Cargador"
                                checked={formData['Cargador']}
                                onChange={handleChange}
                            />
                            Cargador
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Tarjeta Madre"
                                checked={formData['Tarjeta Madre']}
                                onChange={handleChange}
                            />
                            Tarjeta Madre
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Procesador"
                                checked={formData['Procesador']}
                                onChange={handleChange}
                            />
                            Procesador
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Tarjeta de Video"
                                checked={formData['Tarjeta de Video']}
                                onChange={handleChange}
                            />
                            Tarjeta de Video
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Tarjeta de red LAN"
                                checked={formData['Tarjeta de red LAN']}
                                onChange={handleChange}
                            />
                            Tarjeta de red LAN
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Tarjeta de red WLAN"
                                checked={formData['Tarjeta de red WLAN']}
                                onChange={handleChange}
                            />
                            Tarjeta de red WLAN
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Pantalla"
                                checked={formData['Pantalla']}
                                onChange={handleChange}
                            />
                            Pantalla
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Fuente de Poder"
                                checked={formData['Fuente de Poder']}
                                onChange={handleChange}
                            />
                            Fuente de Poder
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Batería interna 3v"
                                checked={formData['Batería interna 3v']}
                                onChange={handleChange}
                            />
                            Batería interna 3v
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Teclado"
                                checked={formData['Teclado']}
                                onChange={handleChange}
                            />
                            Teclado
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Mouse"
                                checked={formData['Mouse']}
                                onChange={handleChange}
                            />
                            Mouse
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Combo inalámbrico"
                                checked={formData['Combo inalámbrico']}
                                onChange={handleChange}
                            />
                            Combo inalámbrico
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Diadema"
                                checked={formData['Diadema']}
                                onChange={handleChange}
                            />
                            Diadema
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Adaptador RJ45 - USB"
                                checked={formData['Adaptador RJ45 - USB']}
                                onChange={handleChange}
                            />
                            Adaptador RJ45 - USB
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Multipuerto USB"
                                checked={formData['Multipuerto USB']}
                                onChange={handleChange}
                            />
                            Multipuerto USB
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Funda Para Portátil"
                                checked={formData['Funda Para Portátil']}
                                onChange={handleChange}
                            />
                            Funda Para Portátil
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Pad Mouse"
                                checked={formData['Pad Mouse']}
                                onChange={handleChange}
                            />
                            Pad Mouse
                        </label>
                        <label>
                            <input
                                type="checkbox"
                                name="Otro"
                                checked={formData['Otro']}
                                onChange={handleChange}
                            />
                            Otro
                        </label>
                    </div>
                </fieldset>
                <fieldset className={styles.fieldset}>
                    <legend>Software Instalado</legend>
                    {/* <div className={styles.softwareGrid}> */}
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_so">Sistema Operativo:</label>
                            <input
                                type="text"
                                name="software_so"
                                value={formData.software_so}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_so"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_office">Suite Ofimática:</label>
                            <input
                                type="text"
                                name="software_office"
                                value={formData.software_office}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_office"
                            />
                        </div>
                    </div>

                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_adobe_reader">Adobe Reader:</label>
                            <input
                                type="text"
                                name="software_adobe_reader"
                                value={formData.software_adobe_reader}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_adobe_reader"
                            />
                        </div>

                        <div className={styles.inputGroup}>
                            <label htmlFor="software_firefox">Mozilla Firefox:</label>
                            <input
                                type="text"
                                name="software_firefox"
                                value={formData.software_firefox}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_firefox"
                            />
                        </div>
                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_otro">Otro software:</label>
                            <input
                                type="text"
                                name="software_otro"
                                value={formData.software_otro}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_otro"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_chrome">Google Chrome:</label>
                            <input
                                type="text"
                                name="software_chrome"
                                value={formData.software_chrome}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_chrome"
                            />
                        </div>
                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_acrobat">Adobe Acrobat:</label>
                            <input
                                type="text"
                                name="software_acrobat"
                                value={formData.software_acrobat}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_acrobat"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_codec">K-Lite Codec Pack:</label>
                            <input
                                type="text"
                                name="software_codec"
                                value={formData.software_codec}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_codec"
                            />
                        </div>
                    </div>
                    <div className={styles.columns}>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_zip">7-Zip:</label>
                            <input
                                type="text"
                                name="software_zip"
                                value={formData.software_zip}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_zip"
                            />
                        </div>
                        <div className={styles.inputGroup}>
                            <label htmlFor="software_otro2">Otro software 2:</label>
                            <input
                                type="text"
                                name="software_otro2"
                                value={formData.software_otro2}
                                onChange={handleChange}
                                className={styles.input}
                                id="software_otro2"
                            />
                        </div>
                    </div>
                    {/* </div> */}
                </fieldset>
                <div className={styles.observationsContainer}>
                    <label htmlFor="observaciones">Observaciones:</label>
                    <textarea
                        name="observaciones"
                        value={formData.observaciones}
                        onChange={handleChange}
                        className={styles.textarea}
                        id="observaciones"
                        required
                    ></textarea>
                </div>

                <div>
                    {/* Selección de satisfacción */}
                    <div>
                        <label><strong>Satisfacción del servicio:</strong></label>
                        <div>
                            <span
                                className={`${styles.emoji}`}
                                onClick={() => handleSatisfaccionChange(1)}
                            >
                                😢
                            </span>
                            <span
                                className={`${styles.emoji}`}
                                onClick={() => handleSatisfaccionChange(2)}
                            >
                                😞
                            </span>
                            <span
                                className={`${styles.emoji}`}
                                onClick={() => handleSatisfaccionChange(3)}
                            >
                                😕
                            </span>
                            <span
                                className={`${styles.emoji}`}
                                onClick={() => handleSatisfaccionChange(4)}
                            >
                                😊
                            </span>
                            <span
                                className={`${styles.emoji}`}
                                onClick={() => handleSatisfaccionChange(5)}
                            >
                                😄
                            </span>
                        </div><br />
                    </div>

                    {/* Mostrar textarea solo cuando se elija una satisfacción */}
                    {value > 0 && (
                        <div className={styles.observationsContainer}>
                            {/* <label>Observaciones:</label> */}
                            <p><strong>Calificacion: {value}</strong></p>
                            <textarea
                                value={observaciones}
                                onChange={handleObservacionesChange}
                                placeholder="Ingrese el motivo de su calificación..."
                                className={styles.textareasatis}
                            />
                        </div>
                    )}
                </div><br />
                {/* <Satisfaccion/> */}
                <button type="submit" onClick={handleCerrar} className={styles.submitButton}>Finalizar y calificar servicio</button>
            </form>
        </div >
    );
};

export default Cerrarticket;
