import {
  GET_USERS, GET_TOKEN, TOKEN_ERROR, LOGOUT, GET_TIPOFALLA, GET_ASIGUSUARIO, GET_SEGUITICKET, GET_TICKHISTORIAL,
  ID_TICKET_HISTORIAL_SUCCESS, UPDATE_TICKET_STATUS_SUCCESS, GET_POSTREGISTROS, GET_AGENDA, DELETE_AGENDA, DELETE_AGENDA_ERROR, TICKET_ASIGNAR, TECNICO_TI, GET_USERS_ERROR,
  GET_ALLTICKETCED, GET_RESOLUCION, GET_ALLTICKUSER, GET_TODOSUSER, GET_CARGO, GET_AREA, GET_ROL, GET_EQUIPOS, GET_SO, GET_MARCA, GET_TIPOEQUI,GET_CRITICI
} from "./actions";

const initialState = {
  usuario: JSON.parse(sessionStorage.getItem('usuario')) || [],
  token: { message: '' },
  tokenError: null,
  usuarioError: null, // Nuevo estado para manejar errores de usuario
  tipofalla: [],
  asigusuario: [],
  estadoticket: [],
  tickhistorial: [],
  tickhistorial: { registros: [] },
  tickregistro: [],
  agenda: [],
  agendaError: null,
  ticketasignar: [],
  tecnicoti: [],
  allticketced: [],
  resolucion: [],
  alltickuser: [],
  allusuarios: [],
  cargo: [],
  area: [],
  rol: [],
  equipos: [],
  sistemasOperativos: [],
  marca: [],
  tipoequipo: [],
  criticidad:[]
};

const rootReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_USERS:
      return { ...state, usuario: action.payload, usuarioError: null };
    case GET_USERS_ERROR: // Nuevo caso para manejar el error
      return { ...state, usuarioError: action.payload };
    case GET_TOKEN:
      return { ...state, token: action.payload, tokenError: null };
    case TOKEN_ERROR:
      return { ...state, tokenError: action.payload };
    case LOGOUT:
      return { ...state, usuario: [], token: [] };
    case GET_TIPOFALLA:
      return { ...state, tipofalla: action.payload };
    case GET_ASIGUSUARIO:
      return { ...state, asigusuario: action.payload };
    case GET_SEGUITICKET:
      return { ...state, estadoticket: action.payload };
    case GET_TICKHISTORIAL:
      return { ...state, tickhistorial: action.payload };
    case ID_TICKET_HISTORIAL_SUCCESS:
      return { ...state, tickhistorial: action.payload };
    case UPDATE_TICKET_STATUS_SUCCESS:
      return {
        ...state,
        tickhistorial: {
          ...state.tickhistorial,
          registros: state.tickhistorial.registros.map((registro) =>
            registro.id === action.payload.id ? action.payload : registro
          ),
        },
      };
    case GET_POSTREGISTROS:
      return { ...state, tickregistro: action.payload };
    case GET_AGENDA:
      return { ...state, agenda: action.payload };
    case DELETE_AGENDA:
      return {
        ...state,
        agenda: state.agenda.filter(agenda => agenda.id !== action.payload),
        agendaError: null,
      };
    case DELETE_AGENDA_ERROR:
      return { ...state, agendaError: action.payload };
    case TICKET_ASIGNAR:
      return { ...state, ticketasignar: action.payload };
    case TECNICO_TI:
      return { ...state, tecnicoti: action.payload };
    case GET_ALLTICKETCED:
      // console.log("Payload de GET_ALLTICKETCED:", action.payload);
      return { ...state, allticketced: action.payload };
    case GET_RESOLUCION:
      return { ...state, resolucion: action.payload };
    case GET_ALLTICKUSER:
      return { ...state, alltickuser: action.payload };
    case GET_TODOSUSER:
      return { ...state, allusuarios: action.payload };
    case GET_CARGO:
      return { ...state, cargo: action.payload };
    case GET_AREA:
      return { ...state, area: action.payload };
    case GET_ROL:
      return { ...state, rol: action.payload };
    case GET_EQUIPOS:
      return { ...state, equipos: action.payload }
    case GET_SO:
      return { ...state, sistemasOperativos: action.payload }
    case GET_MARCA:
      return { ...state, marca: action.payload }
    case GET_TIPOEQUI:
      return { ...state, tipoequipo: action.payload }
    case GET_CRITICI:
      return { ...state, criticidad: action.payload }
    default:
      return state;
  }
};

export default rootReducer;
