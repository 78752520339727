import React, { useMemo, useEffect, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useSelector, useDispatch } from 'react-redux';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { CedAsigequipo } from '../../redux/actions';
import './Grafico.css'; // Import the CSS file

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels);

const Grafico = () => {
  const dispatch = useDispatch();
  const asigusuario = useSelector((state) => state.asigusuario); // Ajusta esto según tu estado
  const usuario = useSelector(state => state.usuario);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);

  useEffect(() => {
    dispatch(CedAsigequipo(usuario.usuario.cedula));
  }, [dispatch]);

  const handleMonthChange = (event) => {
    setSelectedMonth(parseInt(event.target.value, 10));
  };

  // Array con los nombres de los meses
  const monthNames = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 
    'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
  ];
  
  const currentMonth = new Date().getMonth() + 1;
  const months = monthNames.slice(0, currentMonth); // Crear un array con los meses hasta el mes actual

  // Procesar los datos para contar tickets por fecha
  const ticketCounts = useMemo(() => {
    const counts = {};

    asigusuario.forEach(asignacion => {
      asignacion.tickets.forEach(ticket => {
        const date = ticket.ticket.fecha.split('T')[0]; // Obtener la fecha sin la hora
        const month = parseInt(date.split('-')[1], 10);
        if (month === selectedMonth) {
          if (!counts[date]) {
            counts[date] = 0;
          }
          counts[date]++;
        }
      });
    });

    return counts;
  }, [asigusuario, selectedMonth]);

  // Preparar los datos para el gráfico
  const labels = Object.keys(ticketCounts);
  const data = {
    labels: labels,
    datasets: [
      {
        label: 'Tickets por fecha',
        data: labels.map(label => ticketCounts[label]),
        backgroundColor: 'rgba(75, 192, 192, 0.6)',
        borderColor: 'rgba(75, 192, 192, 1)',
        borderWidth: 1,
        datalabels: {
          anchor: 'end',
          align: 'top',
          formatter: (value) => value,
          font: {
            weight: 'bold'
          },
          color: 'black'
        }
      },
    ],
  };

  // Configurar opciones del gráfico
  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: `Cantidad de Tickets para ${monthNames[selectedMonth - 1]}`,
      },
      datalabels: {
        display: true,
        anchor: 'end',
        align: 'top',
        formatter: (value) => value,
        font: {
          weight: 'bold'
        },
        color: 'black'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };

  return (
    <div className="grafico-container">
      <div className="month-selector">
        <label htmlFor="month"></label>
        <select id="month" value={selectedMonth} onChange={handleMonthChange}>
          {months.map((month, index) => (
            <option key={index} value={index + 1}>{month}</option>
          ))}
        </select>
      </div>
      <div className="chart-container">
        <Bar data={data} options={options} />
      </div>
    </div>
  );
};

export default Grafico;
