import React from "react";
import Card from "../Card/Card";
import style from "./CardsContainer.module.css";

export default function CardContainer({ estadoticket }) {
  if (!estadoticket || !Array.isArray(estadoticket) || estadoticket.length === 0) {
    return null;
  }

  return (
    <div className={style.container1}>
      {estadoticket.map((data) =>
        data.tickets.map((ticketData) => (
          <Card key={ticketData.ticketId} ticketData={ticketData} equipoCod={data.equipo.cod} />
        ))
      )}
    </div>
  );
}
